import React from "react";
import { useHistory } from "react-router-dom";
// SEO
import { Helmet } from "react-helmet";

export default function SeoTags(props) {
  const history = useHistory();

  console.log(
    "Page title",
    props.title
      ? `${props.title + process.env.REACT_APP_SITE_NAME}`
      : process.env.REACT_APP_SITE_NAME
  );

  console.log(
    "Canonical URL",
    history.location && history.location.pathname
      ? `${process.env.REACT_APP_SITE_URL + history.location.pathname}`
      : `${process.env.REACT_APP_SITE_URL}`
  );

  return (
    <Helmet>
      <title>
        {props.title
          ? `${props.title + " - " + process.env.REACT_APP_SITE_NAME}`
          : process.env.REACT_APP_SITE_NAME}
      </title>
      <link
        rel="canonical"
        href={
          history.location && history.location.pathname
            ? `${process.env.REACT_APP_SITE_URL + history.location.pathname}`
            : `${process.env.REACT_APP_SITE_URL}`
        }
      />
      {props.metaDescription && (
        <meta name="description" content={props.metaDescription} />
      )}
    </Helmet>
  );
}
