import React, { useState } from "react";
import { ProductImageWrapper } from "../Product.style";

export default function ProjectImagePopup(props) {
  const [activeImage, setActiveImage] = useState(0);

  const handleImageActive = (index) => {
    setActiveImage(index);
  };

  return (
    <ProductImageWrapper
      onClose={props.closeProductImagePopup}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth={"md"}
      fullWidth
    >
      <div className="media-popup">
        <div className="main-media-wrapper">
          <div
            className="image"
            style={{
              backgroundImage: `url("${
                props.productImages && props.productImages[activeImage].original
              }")`,
            }}
          ></div>
        </div>

        <div className="product-gallery">
          <div className="product-name">
            <h1>{props && props.productData && props.productData.p_title}</h1>
          </div>
          <div className="tags-container">
            <div className="tags">
              {props && props.productData.p_secondary_name}
            </div>
          </div>
          <div className="thumb-wrapper">
            {props.productImages &&
              props.productImages.map((image, index) => (
                <div
                  className="media-container"
                  key={index}
                  onClick={() => handleImageActive(index)}
                >
                  <div
                    className={`image ${
                      index === activeImage ? "active" : null
                    }`}
                    style={{ backgroundImage: `url("${image.original}")` }}
                  ></div>
                </div>
              ))}
          </div>
        </div>
        <div className="close" onClick={props.closeProductImagePopup}>
          X
        </div>
      </div>
    </ProductImageWrapper>
  );
}
