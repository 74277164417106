import styled from "styled-components";
import { FONTS } from "Styles/Constants";
import { Dialog } from "@material-ui/core";

export const DownlaodAppWrapper = styled(Dialog)`
  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    .close {
      cursor: pointer;
    }
  }

  .button-container {
    border-top: 1px solid #ccc;
    padding: 15px;
    text-align: center;
    .root {
      text-transform: none;
    }
  }
`;
