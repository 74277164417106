import styled from "styled-components";
import { FONTS } from "Styles/Constants";

export const NoDataFoundWrapper = styled.div`
  .no-data-found {
    text-align: center;
    margin: 50px 0;
    height: 38vh;
    .title {
      font-size: 24px;
      font-family: ${FONTS.PRIMARY_MEDIUM};
      margin-bottom: 15px;
    }
    .button-container {
      text-align: center;
      .root {
        min-width: 280px;
        background-color: #009036;
        color: #fff;
        text-transform: none;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 18px;
        padding: 10px;
        border-radius: 25px;
        border: none;
      }
    }
  }
`;
