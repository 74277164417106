import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";

export const GhareluUpcharWrapper = styled.div`
  overflow: auto;
  margin-top: 23px;
  background-color: #fff;
  overflow: auto;
  ${responsive.TABLET`
      margin-top: 0px;
  `}

  ${responsive.MOBILE`
      margin-top: 60px;
  `}
  
  .breadcrumbs {
    background-color: #edf1f0;
    .breadcrumb {
      display: flex;
      padding: 20px 15px;
      .element {
        text-transform: uppercase;
        margin-right: 10px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 16px;
      }
      .seperator {
        font-size: 18px;
      }
    }
  }
  .tags-container {
    margin: 10px 0;
    .tags {
      color: #aaaaaa;
    }
  }
  .gharelu-upchar {
    width: 100%;

    .video-container {
      width: 100%;
      text-align: center;
      margin: 25px;
      overflow: auto;
      .react-player {
        margin: 0 auto;
        ${responsive.PHABLET`
          width: 330px !important;
          height: 185px !important;
          overflow-x: hidden;
        `}
      }
      ${responsive.PHABLET`
          margin: 10px auto;
      `}
    }

    .image-container {
      width: 100%;
      height: 320px;
      margin: 25px;
      .image {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border-bottom-right-radius: 15px;
      }
    }

    .title-container {
      margin: 15px 0;
      text-align: center;
      .title {
        font-family: ${FONTS.PRIMARY_BOLD};
        font-size: 18px;
      }
    }

    .description-container {
      padding: 15px 0;
      width: 100%;
      background: #edf1f0;
      padding: 15px;
      margin-bottom: 15px;
      .accordian {
        box-shadow: none;
      }
      .name {
        color: ${COLORS.PRIMARY};
        font-size: 20px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
      }
      .description {
        font-family: ${FONTS.PRIMARY_MEDIUM};
      }
    }
  }
`;
