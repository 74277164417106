import React from "react";
import { AboutWrapper } from "./AboutUs.style";
export default function AboutUs() {
  return (
    <div className="container">
      <AboutWrapper>
        <div className="content-container">
          <p className="content">
            Swasth Arogya is known for working towards the better health of
            people since many years. Thousands of people are benefited from the
            health solution provided by Swasth Arogya. Swasth Arogya is a well
            known and trusted name in the world of Ayurveda. Health solutions
            through natural ingredients are the key factor of our company. With
            a team of more than 100 people, Swasth Arogya has earned the trust
            of its customer for providing natural remedies to their health
            issues.
          </p>
          <p className="content">
            Along with a range of Ayurvedic Medicines, Swasth Arogya is also
            known for a range of Ayurvedic Powders like powders of pure herbs or
            classical medicines and Herbal Cosmetics like Facewash, Handwash,
            Scrubs, Massage Creams, Hair Oil and Shampoos, etc. People usually
            trust Swasth Arogya Products for quality and its result.
          </p>
        </div>
      </AboutWrapper>
    </div>
  );
}
