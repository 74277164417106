import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";
import PlayStoreIcon from "Assets/Images/Footer/playStore.png";
import PaytmIcon from "Assets/Images/Footer/paytm.png";
import PayuIcon from "Assets/Images/Footer/payu.png";
import PhonePeIcon from "Assets/Images/Footer/phonepe.png";
import MobikWikIcon from "Assets/Images/Footer/mobikwik.png";
import GPayIcon from "Assets/Images/Footer/gpay.png";
// SOCIAL ICON
import FbIcon from "Assets/Images/Footer/fb.png";
import GooglePlusIcon from "Assets/Images/Footer/google-plus.png";
import InstaIcon from "Assets/Images/Footer/insta.png";
import TwitterIcon from "Assets/Images/Footer/twitter.png";
import WhatsappIcon from "Assets/Images/Footer/whatsapp.png";
import YouTubeIcon from "Assets/Images/Footer/youtube.png";
import LinkedInIcon from "Assets/Images/Footer/ln.png";

import { Dialog } from "@material-ui/core";

export const FooterContainer = styled.div`
  background-color: ${COLORS.PRIMARY_DARK};
  overflow: auto;

  .section-container {
    display: flex;
    margin: 20px auto;
    ${responsive.PHABLET` flex-direction: column;`}

    .section-information {
      width: 33%;

      ${responsive.TABLET` width: 45%; text-align:center;`}
      ${responsive.PHABLET` width: 100%; text-align:center; `}

      .title-conainer {
        padding: 15px 0;
        .title {
          color: #fff;
          font-size: 18px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          letter-spacing: 0.3px;
        }
      }

      .link-container {
        .link {
          margin: 10px 0;
          a {
            cursor: pointer;
            color: #818985;
            text-decoration: none;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }

    .section-categories {
      width: 33%;
      padding: 0 10px;
      ${responsive.TABLET` width: 45%; text-align:center;`}
      ${responsive.PHABLET` width: 100%; text-align:center; `}

      .title-conainer {
        padding: 15px 0;
        .title {
          color: #fff;
          font-size: 18px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          letter-spacing: 0.3px;
        }
      }

      .link-container {
        .link {
          margin: 10px 0;
          a {
            cursor: pointer;
            color: #818985;
            text-decoration: none;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }

    .section-address {
      width: 33%;
      padding: 0 10px;
      ${responsive.TABLET` width: 100%; text-align:center;`}
      ${responsive.PHABLET` width: 100%; text-align:center; `}

      .title-conainer {
        padding: 15px 0;
        .title {
          color: #fff;
          font-size: 18px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          letter-spacing: 0.3px;
        }
      }
      .address {
        .field {
          padding: 5px 0;
          color: #818985;
          font-size: 16px;
          line-height: 24px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          letter-spacing: 0.3px;
          .white {
            color: #fff;
          }
        }
      }
      .button-container {
        margin: 15px 0;
        .root {
          background-color: #009036;
          padding: 12px 25px;
          color: #fff;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 16px;
          border-radius: 4px;
        }
        img {
          margin-right: 10px;
        }
      }
    }
  }

  .section-coptright {
    background-color: #010c07;
    overflow: auto;
    .coptright-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
      ${responsive.TABLET` flex-wrap: wrap;`}

      .playstore-container {
        .icon {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 60px;
          width: 170px;
          &.play-store {
            background-image: url(${PlayStoreIcon});
          }
          ${responsive.TABLET` margin: 0 auto;`}
        }
        ${responsive.TABLET` width: 100%; text-align:center;`}
      }
      .copyright {
        margin: 0 10px;
        .text {
          color: #818985;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 16px;
        }
        ${responsive.TABLET` width: 100%; text-align:center; margin : 15px 0;`}
      }
      .payment-providers {
        display: flex;
        align-items: center;
        justify-content: center;
        ${responsive.TABLET` width: 100%; text-align:center;`}
        .icon {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 50px;
          width: 80px;
          margin-right: 10px;
          &.payu {
            background-image: url(${PayuIcon});
          }
          &.gpay {
            background-image: url(${GPayIcon});
          }
          &.paytm {
            background-image: url(${PaytmIcon});
          }
          &.phonepe {
            background-image: url(${PhonePeIcon});
          }
          &.mobikwik {
            background-image: url(${MobikWikIcon});
          }
        }
      }

      .social-media {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        ${responsive.TABLET` width: 100%; text-align:center;`}

        .icon {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 50px;
          width: 80px;
          margin-right: 10px;
          &.fb {
            background-image: url(${FbIcon});
          }
          &.insta {
            background-image: url(${InstaIcon});
          }
          &.google-plus {
            background-image: url(${GooglePlusIcon});
          }
          &.youtube {
            background-image: url(${YouTubeIcon});
          }
          &.linkedin {
            background-image: url(${LinkedInIcon});
          }
          &.whatsapp {
            background-image: url(${WhatsappIcon});
          }
          &.twitter {
            background-image: url(${TwitterIcon});
          }
        }
      }
    }
  }
`;

export const CallNowPopupContainer = styled(Dialog)`
  .call-now {
    padding: 15px;
    .text-container {
      font-size: 16px;
      margin: 10px 0 0;
      font-family: ${FONTS.PRIMARY_MEDIUM};
      text-align: center;
      .title {
        color: #818985;
      }
    }

    .button-container {
      text-align: center;
      margin: 10px 0 0;
      .root {
        background-color: #009036;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 14px;
        padding: 10px;
        min-width: 150px;
        text-transform: none;
        margin-right: 10px;
        border: none;
        color: #fff;
      }
    }
  }
`;
