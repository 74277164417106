import styled from "styled-components";
import { FONTS, responsive } from "Styles/Constants";
import { Dialog } from "@material-ui/core";

export const ContactUsDailogWrapper = styled(Dialog)`
  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    .close {
      cursor: pointer;
    }
  }
  .field {
    padding: 10px 20px;
    max-height: 600px;
    overflow: auto;
    .label {
      color: #8f9493;
      font-size: 14px;
      font-family: ${FONTS.PRIMARY_MEDIUM};
      margin: 8px 0;
    }

    .view-field {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .label {
        width: 100px;
      }
      .value {
        width: 250px;
        text-align: right;
      }
    }

    .required {
      color: #fe3434;
    }
    .input {
      .root {
        min-width: 500px;
        font-size: 14px;
        font-family: ${FONTS.PRIMARY_MEDIUM};

        ${responsive.MOBILE`
          min-width: 200px;
        `}
        input {
          padding: 14px;
        }
      }
    }
    .select {
      .select-menu {
        padding: 14px;
        min-width: 470px;
      }
    }

    .upload {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      background-color: #f9f9f9;
    }

    .input-file {
      display: none;
    }
    .root-btn {
      margin: 12px;
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .button-container {
    border-top: 1px solid #ccc;
    padding: 15px;
    text-align: right;
    .root {
      text-transform: none;
    }
  }
`;
