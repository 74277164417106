export const ACTION_TYPES = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  DEFAULT_ADDRESS: "DEFAULT_ADDRESS",
  FETCH_CART: "FETCH_CART",
};

export const loginUser = (data) => {
  // SET YOUR LOGIN INFO HERE
  localStorage.setItem("isLoggedIn", true);
  localStorage.setItem("userInfo", JSON.stringify(data));
  localStorage.setItem("token", data.token);
  return {
    type: ACTION_TYPES.LOGIN_USER,
    token: data.token,
    userInfo: data,
  };
};

export const logOutUser = () => {
  localStorage.clear();
  return {
    type: ACTION_TYPES.LOGOUT_USER,
  };
};

export const updateUserInfo = (values) => {
  localStorage.setItem("userInfo", JSON.stringify(values));
  return {
    type: ACTION_TYPES.UPDATE_USER_INFO,
    values,
  };
};

export const setDefaultAddress = (values) => {
  return {
    type: ACTION_TYPES.DEFAULT_ADDRESS,
    values,
  };
};

export const setUserCartData = (values) => {
  return {
    type: ACTION_TYPES.FETCH_CART,
    values,
  };
};
