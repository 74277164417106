import React from "react";
import { CircularProgress } from "@material-ui/core";
import { ProgressBarWrapper } from "./ProgressBar.style";

export default function ProgressBar() {
  return (
    <ProgressBarWrapper>
      <div className="container">
        <div className="logo-container">
          <div className="logo" />
          <div className="text">Loading...</div>
          <CircularProgress />
        </div>
      </div>
    </ProgressBarWrapper>
  );
}
