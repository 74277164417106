import React, { Component } from "react";
import { ProductGalleryWrapper } from "../Product.style";
import ImageGallery from "react-image-gallery";
import ProductImagePopup from "./ProjectImagePopup";
import "react-image-gallery/styles/css/image-gallery.css";

class ProductGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryCarousel: null,
      variantCarousel: null,
      photoIndex: 0,
      isOpen: false,
      productImagePopup: false,
    };
  }

  handleOpenLightbox = (e, imageIndex) => {
    e.preventDefault();
    this.setState({ photoIndex: imageIndex, isOpen: false });
  };

  componentDidMount() {
    this.setState({
      galleryCarousel: this.slider1,
      variantCarousel: this.slider2,
    });
  }

  render() {
    const { images } = this.props;

    const productImages = [];
    images.forEach((variant) => {
      productImages.push({
        original: variant,
        thumbnail: variant,
        originalHeight: "360px",
        originalWidth: "360px",
      });
    });

    const handleOnProductImageClick = (e) => {
      this.setState({
        productImagePopup: true,
      });
    };

    const closeProductImagePopup = () => {
      this.setState({
        productImagePopup: false,
      });
    };

    const properties = {
      thumbnailPosition: "left",
      useBrowserFullscreen: false,
      showPlayButton: false,
      items: productImages,
      showFullscreenButton: false,
      onClick: (e) => handleOnProductImageClick(e),
    };

    return (
      <ProductGalleryWrapper>
        <div className="ps-product__variants">
          <div className="ps-wrapper">
            <ImageGallery {...properties} />
          </div>
        </div>
        {this.state.productImagePopup && (
          <ProductImagePopup
            closeProductImagePopup={closeProductImagePopup}
            productImages={productImages}
            open={this.state.productImagePopup}
            productData={this.props.productData}
          />
        )}
      </ProductGalleryWrapper>
    );
  }
}

export default ProductGallery;
