import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { List, ListItem } from "@material-ui/core";
import {
  URL_CART,
  URL_HOME,
  URL_LOGIN,
  URL_ORDERS,
  URL_CATEGORY,
  URL_ADDRESS,
  URL_ABOUT_US,
  //URL_DONATE_NOW,
  URL_KNOWLEDGE_CENTER,
  URL_GHARELU_UPCHAR,
} from "Helpers/Paths";
import {
  ToolbarWrapper,
  LinksWrapper,
  AppBarWrapper,
  PopOver,
} from "./Topbar.style";

import DownloadApp from "Components/Commons/Dialog/DownloadApp";

import { connect, useSelector, useDispatch } from "react-redux";
import { toggleSidebar, showToast } from "Redux/General/Actions";
import {
  logOutUser,
  setDefaultAddress,
  setUserCartData,
} from "Redux/Auth/Actions";

import SelectAddress from "Components/Address/Dialog/SelectAddress";
import ProfileDialog from "Components/Profile/ProfileDialog";
//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { SidebarWrapper } from "../Sidebar/Sidebar.style";
import { BREAKPOINTS_VALUE } from "Styles/Constants";

const TOPBAR_URL = [
  {
    url: URL_HOME,
    name: "Home",
  },
  {
    url: "/sub-categories/1",
    name: "Shop by Product",
  },
  {
    url: URL_CATEGORY,
    name: "Shop by Category",
  },
  {
    url: URL_KNOWLEDGE_CENTER,
    name: "Knowledge Center",
  },
  {
    url: URL_GHARELU_UPCHAR,
    name: "Gharelu Upchar",
  },
  /*{
    url: URL_DONATE_NOW,
    name: "Donate Now",
  },*/
  {
    url: URL_ABOUT_US,
    name: "About Us",
  },
];

function TopBar(props) {
  const dispatch = useDispatch();
  let { userInfo, defaultAddress, isLoggedIn, cartData } = useSelector(
    (state) => state.Auth
  );

  const [searchText, setSeachText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddress, setOpenAddress] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openDrawer, setDrawerOpen] = useState(false);
  const [openDownladApp, setOpenDownladApp] = useState(false);

  useEffect(() => {
    fetchDefualtAddress();
    fetchCartDetail();
    // eslint-disable-next-line
  }, [userInfo]);

  const fetchCartDetail = async () => {
    if (isLoggedIn && userInfo && userInfo.id) {
      let formData = new FormData();
      formData.append("client_id", userInfo.id);
      const result = await new Api().post("Client/Cart/view", {
        data: formData,
      });

      if (result.status !== CODES.SUCCESS) {
        dispatch(showToast(result.data.message));
        return;
      }
      if (result.data.data) {
        dispatch(setUserCartData(result.data.data));
      }
    }
  };

  const handlePopoverOpen = (event) => {
    if (isLoggedIn) {
      setAnchorEl(event.currentTarget);
    } else {
      redirectToLogin();
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function navigateTo(url) {
    // props.history.push(url)
    setDrawerOpen(false);
  }

  const redirectToLogin = () => {
    props.history.push(URL_LOGIN);
  };

  const logout = async () => {
    setAnchorEl(null);
    dispatch(logOutUser());
    props.history.push(URL_LOGIN);
    dispatch(showToast("You have logout successfully."));
  };

  const closeSidebar = () => {
    setDrawerOpen(false);
  };

  const redirectTohome = () => {
    props.history.push(URL_HOME);
  };

  const openSelectAddress = () => {
    if (isLoggedIn && userInfo) setOpenAddress(true);
    else redirectToLogin();
  };

  const closeSelectAddress = () => {
    setOpenAddress(false);
  };

  const openProfileDialog = () => {
    if (isLoggedIn) {
      setOpenProfile(true);
      setAnchorEl(false);
    } else {
      redirectToLogin();
    }
  };

  const closeProfileDialog = () => {
    setOpenProfile(false);
  };

  const closeDownloadApp = () => {
    setOpenDownladApp(false);
  };

  const fetchDefualtAddress = async () => {
    if (userInfo && userInfo.id && isLoggedIn) {
      let formData = new FormData();
      formData.append("client_id", userInfo.id);

      const address = await new Api().post("Client/Address/getDefaultAddress", {
        data: formData,
        isMultipart: true,
      });
      if (address.status !== CODES.SUCCESS) {
        dispatch(showToast(address.data.message));
        return;
      }

      if (address.data.data) {
        localStorage.setItem(
          "defaultAddress",
          JSON.stringify(address.data.data)
        );
        dispatch(setDefaultAddress(address.data.data));
      }
    }
  };

  const redirectToCart = () => {
    if (userInfo && userInfo.id) {
      props.history.push(URL_CART);
    } else {
      redirectToLogin();
    }
  };

  const handleSearchTextChange = (e) => {
    setSeachText(e.target.value);
  };

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const fetchGlobalSearch = async () => {
    if (searchText && searchText !== "") {
      let formData = new FormData();
      formData.append("keyword", searchText);

      const result = await new Api().post("Client/Products/globalSearch", {
        data: formData,
      });
      if (result.status !== CODES.SUCCESS) {
        dispatch(showToast(result.data.message));
        return;
      }

      if (result.data.data) {
        props.history.push({
          pathname: `/product-list`,
          state: {
            products: result.data.data,
            isHomeData: true,
            category: {
              name: searchText,
            },
          },
        });
        setSeachText("");
      }
    }
  };

  const redirectToURL = (url) => {
    if (userInfo && userInfo.id) {
      props.history.push(url);
    } else {
      props.history.push(URL_LOGIN);
    }
    setAnchorEl(null);
  };

  const redirectToAppDownloadPage = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.swastharogya",
      "_blank"
    );
  };

  const URLS = TOPBAR_URL;
  const open = Boolean(anchorEl);

  let checkVariant = visibilityCheck();
  let activeUrl = props.history.location.pathname.split("/")[1];

  // MOBILE APPLICATION DOWLOAD POP UP LOGIC
  useEffect(() => {
    const updateWindowDimensions = () => {
      console.log("Width Updated");
    };

    window.addEventListener("resize", updateWindowDimensions);

    if (window.innerWidth <= BREAKPOINTS_VALUE.LAPTOP) {
      setOpenDownladApp(false);
    } else {
      setOpenDownladApp(false);
    }

    return () => window.removeEventListener("resize", updateWindowDimensions);
    // eslint-disable-next-line
  }, []);

  return (
    <AppBarWrapper position="fixed" classes={{ root: "root" }}>
      <ToolbarWrapper classes={{ gutters: "gutters", root: "" }}>
        {/*isLoggedIn && <div className="menu" />*/}
        <div className="menu-container">
          <div className="mobile-menu-icon" onClick={handleOpenDrawer}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className="logo" onClick={redirectTohome} />
          <div className="location-container">
            <div className="icon location" />
            <div className="text-container" onClick={openSelectAddress}>
              {isLoggedIn && defaultAddress ? (
                <React.Fragment>
                  <div className="title">
                    Deliver to{" "}
                    {userInfo && userInfo.first_name ? userInfo.first_name : ""}
                  </div>
                  <div className="address">
                    {defaultAddress.city} {defaultAddress.zip_code}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="title">Hello,</div>
                  <div className="address">Select Your Address</div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="search-container">
            <input
              className="input"
              placeholder="Search"
              value={searchText}
              onChange={handleSearchTextChange}
            />
            <div className="search" onClick={fetchGlobalSearch}>
              <div className="icon"></div>
            </div>
          </div>
          <div className="language">
            <div className="pointer">EN</div>
          </div>
          <div className="account-container" onClick={handlePopoverOpen}>
            <div className="title">
              {isLoggedIn && userInfo
                ? `Hello, ${userInfo.first_name ? userInfo.first_name : ""}`
                : "Hello, Sign in"}
            </div>
            <div className="selection-menu">
              <div className="item">Account & List</div>
              <div className="icon dropdownm" />
            </div>
          </div>
          <div
            className="order-container"
            onClick={() => redirectToURL(URL_ORDERS)}
          >
            <div className="title">My</div>
            <div className="order">Orders</div>
          </div>
          <div className="buttons pointer" onClick={redirectToCart}>
            <div className="border-container">
              <div className="cart border" />
              {cartData &&
                cartData.total_quantity &&
                cartData.total_quantity > 0 && (
                  <div className="bobble">{cartData.total_quantity}</div>
                )}
            </div>
          </div>
        </div>

        {!checkVariant && !isLoggedIn && (
          <div
            className="right-sidebar-icon"
            onClick={() => setDrawerOpen(true)}
            style={{ color: "#fff" }}
          />
        )}

        <SidebarWrapper
          open={openDrawer}
          variant="temporary"
          anchor="right"
          PaperProps={{
            className: "drawer-paper",
          }}
          onClose={closeSidebar}
        >
          <LinksWrapper className="sidebar">
            {URLS.map((item, index) => (
              <Link
                className={`link sidebar ${
                  item.url.split("/")[1] === activeUrl && "active"
                }`}
                to={`${item.url}`}
                key={index}
                onClick={() => navigateTo(item.url)}
              >
                {item.name}
              </Link>
            ))}
          </LinksWrapper>
          <div className="logout-text" onClick={logout}>
            Logout
          </div>
        </SidebarWrapper>

        <PopOver
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <List>
            <ListItem className="logout" onClick={openProfileDialog}>
              Profile
            </ListItem>
            <ListItem
              className="logout"
              onClick={() => redirectToURL(URL_ORDERS)}
            >
              My Orders
            </ListItem>
            <ListItem
              className="logout"
              onClick={() => redirectToURL(URL_ADDRESS)}
            >
              Address
            </ListItem>
            <ListItem className="logout" onClick={logout}>
              Log Out
            </ListItem>
          </List>
        </PopOver>
      </ToolbarWrapper>

      <div className="link-container" id="topbar-link">
        <div className="container ">
          {checkVariant && (
            <LinksWrapper>
              {URLS.map((item, index) => (
                <React.Fragment key={index}>
                  <Link
                    className={`link ${
                      item.url.split("/")[1] === activeUrl && "active"
                    }`}
                    to={`${item.url}`}
                    onClick={() => navigateTo(item.url)}
                  >
                    {item.name}
                  </Link>
                </React.Fragment>
              ))}
              <div
                className="logo small-icon pointer"
                onClick={redirectToAppDownloadPage}
              />
              <div
                className="link app-download pointer"
                onClick={redirectToAppDownloadPage}
              >
                Shopping Made easy | Download the App
              </div>
            </LinksWrapper>
          )}
        </div>
      </div>
      {openAddress && (
        <SelectAddress
          open={openAddress}
          closeSelectAddress={closeSelectAddress}
        />
      )}

      {openProfile && (
        <ProfileDialog
          open={openProfile}
          closeProfileDialog={closeProfileDialog}
        />
      )}

      {openDownladApp && (
        <DownloadApp
          open={openDownladApp}
          closeDownloadApp={closeDownloadApp}
        />
      )}
    </AppBarWrapper>
  );
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
});

export function visibilityCheck() {
  return window.innerWidth >= BREAKPOINTS_VALUE.LAPTOP;
}

export default withRouter(
  connect(mapReduxStateToProps, { toggleSidebar, logOutUser })(TopBar)
);
