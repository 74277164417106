import React from "react";
import { CallNowPopupContainer } from "./Footer.style";
import { Button } from "@material-ui/core";

export default function CallNowPopup(props) {
  return (
    <CallNowPopupContainer
      onClose={props.closeCallNowPopup}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
    >
      <div className="call-now">
        <div className="text-container">
          <span className="title">Customer Care Number</span> : 07969108108
        </div>
        <div className="text-container">
          <span className="title">WhatsApp number</span> : 7228956789
        </div>
        <div className="button-container">
          <Button
            variant="outlined"
            color="primary"
            classes={{ root: "root" }}
            onClick={props.closeCallNowPopup}
          >
            Okay
          </Button>
        </div>
      </div>
    </CallNowPopupContainer>
  );
}
