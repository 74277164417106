import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectAddressDailog } from "../Address.style";
import { Button } from "@material-ui/core";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";
import { setDefaultAddress } from "Redux/Auth/Actions";
// URL
import { URL_ADDRESS } from "Helpers/Paths";

export default function CustomizedDialogs(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addressList, setAddressList] = useState([]);
  let { userInfo } = useSelector((state) => state.Auth);

  useEffect(() => {
    fetchAddress();
    // eslint-disable-next-line
  }, [userInfo]);

  const changeDefaultAddress = async (addressData) => {
    if (userInfo && userInfo.id) {
      let formData = new FormData();
      formData.append("client_id", userInfo.id);
      formData.append("address_id", addressData.id);

      const address = await new Api().post(
        "Client/Address/update_address_status",
        {
          data: formData,
          isMultipart: true,
        }
      );

      if (address.status !== CODES.SUCCESS) {
        dispatch(showToast(address.data.message));
        return;
      }

      if (address.data?.data) {
        setAddressList([...address.data.data]);
        dispatch(setDefaultAddress(addressData));
        dispatch(showToast("Default Address has been changed successfully."));
        props.closeSelectAddress();
      }
    }
  };

  const fetchAddress = async () => {
    if (userInfo && userInfo.id) {
      let formData = new FormData();
      formData.append("client_id", userInfo.id);

      const address = await new Api().post("Client/Address/getAddress", {
        data: formData,
        isMultipart: true,
      });
      if (address.status !== CODES.SUCCESS) {
        dispatch(showToast(address.data.message));
        return;
      }

      if (address.data?.data) setAddressList(address.data.data);
    }
  };

  const redirectToAddress = () => {
    props.closeSelectAddress();
    history.push(URL_ADDRESS);
  };

  return (
    <SelectAddressDailog
      onClose={props.closeSelectAddress}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
    >
      <div className="title-container">
        <div className="title">Choose your Address</div>
        <div
          aria-label="close"
          className="close"
          onClick={props.closeSelectAddress}
        >
          X
        </div>
      </div>
      <div className="address-container">
        {addressList &&
          addressList.map((item) => (
            <div
              className={`address ${Number(item.status) && "active"}`}
              onClick={() => changeDefaultAddress(item)}
            >
              <div className="title">{item.client_name}</div>
              <div className="line-container">
                <div className="text line-1">{item.address_1} ,</div>
                <div className="text line-2">{item.address_2}</div>
              </div>
              <div className="area-container">
                <div className="text area">{item.area} ,</div>
                <div className="text state">{item.state} ,</div>
                <div className="text zip-code">{item.zip_code}</div>
              </div>
            </div>
          ))}
      </div>
      <div className="button-container">
        <Button
          color="primary"
          classes={{ root: "root" }}
          onClick={redirectToAddress}
          variant="outlined"
        >
          Go to Address
        </Button>
      </div>
    </SelectAddressDailog>
  );
}
