import styled from "styled-components";
import { FONTS, COLORS, responsive } from "Styles/Constants";
import { Dialog } from "@material-ui/core";

export const AddressWrapper = styled.div`
  background-color: #fff;
  margin-top: 23px;
  overflow: auto;
  min-height: 48vh;
  ${responsive.TABLET`
      margin-top: 0px;
  `}

  ${responsive.MOBILE`
      margin-top: 60px;
  `}
  .breadcrumbs {
    background-color: #edf1f0;
    .breadcrumb-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .breadcrumb {
        display: flex;
        padding: 20px 15px;
        .element {
          text-transform: uppercase;
          margin-right: 10px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 16px;
        }
        .seperator {
          font-size: 18px;
        }
      }
    }
  }

  .address-container {
    .address-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 15px;
      border-bottom: 1px solid #ccc;

      &.active {
        background-color: ${COLORS.PRIMARY_LIGHT};
      }

      ${responsive.MOBILE`
        flex-wrap:wrap;
      `}

      .address {
        .title {
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 16px;
        }
        .line-container {
          display: flex;
          .line-1 {
            margin-right: 5px;
            padding-bottom: 0;
          }
          .line-2 {
            padding-bottom: 0;
          }
        }
        .area-container {
          display: flex;
          .text {
            margin-right: 5px;
          }
        }
        .text {
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 14px;
          padding: 5px 0;
        }

        ${responsive.MOBILE`
            width:100%;
        `}
      }
    }
  }

  .button-container {
    .root {
      border-radius: 4px;
    }
    ${responsive.MOBILE`
      margin-top:5px;
      .MuiButton-text{
        padding : 6px 0;
      }
    `}
  }
`;

export const SelectAddressDailog = styled(Dialog)`
  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    .close {
      cursor: pointer;
    }
  }
  .address-container {
    max-height: 300px;
    min-height: 300px;
    overflow: auto;

    .address {
      cursor: pointer;
      padding: 15px;
      border-bottom: 1px solid #ccc;
      &.active {
        background-color: ${COLORS.PRIMARY_LIGHT};
      }

      .title {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 16px;
      }
      .line-container {
        display: flex;
        .line-1 {
          margin-right: 5px;
        }
      }
      .area-container {
        display: flex;
        .text {
          margin-right: 5px;
        }
      }
      .text {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 14px;
        padding: 5px 0;
      }
    }
  }
  .button-container {
    border-top: 1px solid #ccc;
    padding: 15px;
    text-align: right;
    .root {
      text-transform: none;
    }
  }
`;

export const AddAddressDailog = styled(Dialog)`
  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    .close {
      cursor: pointer;
    }
  }
  .field {
    padding: 10px 20px;
    max-height: 500px;
    overflow: auto;
    .label {
      color: #8f9493;
      font-size: 14px;
      font-family: ${FONTS.PRIMARY_MEDIUM};
      margin: 8px 0;
    }
    .required {
      color: #fe3434;
    }
    .input {
      .root {
        min-width: 500px;
        font-size: 14px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        ${responsive.MOBILE`
          min-width: 250px;
        `}

        input {
          padding: 14px;
          ${responsive.MOBILE`
              padding: 10px;
              font-size:14px;
          `}
        }
      }
    }
    .select {
      .select-menu {
        padding: 14px;
        min-width: 470px;
        ${responsive.MOBILE`
          min-width: 228px;
          padding: 10px;
          font-size:14px;
        `}
      }
    }
  }

  .button-container {
    border-top: 1px solid #ccc;
    padding: 15px;
    text-align: right;
    .root {
      text-transform: none;
    }
  }
  .zip-code-container {
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);

    .field {
      .input {
        margin-bottom: 5px;
        .root {
          min-width: 320px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          ${responsive.MOBILE`
              min-width: 150px;
          `}
        }
      }
    }
    .button-container {
      margin-top: 30px;
      border-top: none;
    }
  }
`;
