import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";
import LogoLargeIcon from "Assets/Images/Login/logo-large.png";
import ResendIcon from "Assets/Images/Login/Vector.png";

export const LoginWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  ${responsive.TABLET`
    margin-top: 0px;
  `}

  ${responsive.MOBILE`
    margin-top: 60px;
  `}

  .logo-container {
    background-color: ${COLORS.PRIMARY};
    width: 40%;
    padding: 275px 0;
    ${responsive.TABLET`
      display:none;
    `}
    .logo {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(${LogoLargeIcon});
      width: 355px;
      height: 88px;
      margin: 0 auto;
    }
  }
  .login-container {
    width: 60%;
    padding: 25px;
    ${responsive.TABLET`
       width: 100%;
    `}
    .help-container {
      text-align: right;
      .help {
        font-size: 20px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        color: #000;
      }
    }

    .container {
      width: 55%;
      margin: 8% auto;
      display: flex;
      flex-direction: column;
      ${responsive.TABLET`
        width: 100%;
      `}
      .title {
        h1 {
          text-align: center;
          text-transform: uppercase;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 24px;
          padding: 50px 0;

          ${responsive.TABLET`
            padding: 25px 0;
          `}
        }
      }
      .field {
        margin: 0 50px;
        ${responsive.MOBILE`
            margin: 0;
        `}
        .label {
          color: #8f9493;
          font-size: 18px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          margin: 15px 0;
        }
        .required {
          color: #fe3434;
        }
        .input {
          .root {
            min-width: 500px;
            font-family: ${FONTS.PRIMARY_MEDIUM};
            ${responsive.MOBILE`
              min-width: 285px;
            `}
          }
        }
        .button-container {
          margin: 20px 0;
          .root {
            font-size: 18px;
            font-family: ${FONTS.PRIMARY_MEDIUM};
            min-width: 200px;
            text-transform: none;
          }
        }
      }
    }
  }
`;

export const VerifyOtpWrapper = styled.div`
  height: 100%;
  display: flex;
  margin-top: 20px;
  ${responsive.TABLET`
      margin-top: 0px;
  `}

  ${responsive.MOBILE`
    margin-top: 60px;
  `}

  .logo-container {
    background-color: ${COLORS.PRIMARY};
    width: 40%;
    padding: 250px 0;
    ${responsive.TABLET`
      display:none;
    `}
    .logo {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(${LogoLargeIcon});
      width: 355px;
      height: 88px;
      margin: 0 auto;
    }
  }

  .section {
    width: calc(100% - 37.5%);
    background-color: #fff;
    padding: 25px;
    ${responsive.TABLET`
      width: 100%;
    `}
    .help-container {
      text-align: right;
      .help {
        font-size: 20px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        color: #000;
      }
    }

    .container {
      width: 85%;
      margin: 8% auto;
      display: flex;
      flex-direction: column;

      ${responsive.MOBILE`
        width: 100%;
      `}

      .title {
        font-size: 32px;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        color: ${COLORS.TITLE_TEXT_COLOR};
        padding: 25px 0;
      }

      .sub-title {
        font-size: 20px;
        color: #8f9bb3;
        margin: 10px 0;
      }
    }

    .text-fields {
      display: flex;
      width: 50%;
      margin: 50px 0 0;
      ${responsive.MOBILE`
        margin: 25px 0 0;
      `}

      input {
        font-size: 22px;
        color: #000;
        text-align: center;
        ${responsive.MOBILE`
          font-size: 20px;
          padding : 6px;
        `}
      }
      .root {
        background-color: #f9f9f9;
        margin: 0 15px;
        ${responsive.MOBILE`
          margin: 0 5px;
        `}
      }
    }

    .verify-btn {
      width: 25%;
      margin: 50px 0;
      height: 50px;
      text-transform: none;
      font-size: 15px;
      font-family: ${FONTS.PRIMARY};

      &:hover {
        background-color: ${COLORS.PRIMARY};
      }
    }

    .resend {
      font-size: 15px;
      margin: 30px 0 0;
      width: 50%;
      display: flex;
      &:hover {
        cursor: pointer;
      }
      .icon {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        background-image: url(${ResendIcon});
        margin: 0 10px;
      }
    }
  }
`;
