import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SubCategoriesWrapper } from "./SubCategories.style.js";
import { Tooltip } from "@material-ui/core";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";

// URL
import { URL_HOME } from "Helpers/Paths";
import ProgressBar from "Components/Commons/ProgressBar/ProgressBar";

//SEO
import SeoTags from "Components/SeoTags/SeoTags";

export default function SubCategoryList(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [subCategories, setSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    if (params && params.categoryID) {
      // CLICK ON PERTICULER ID
      fetchDisesaseListByCategory(params.categoryID);
    } else if (history.location.state && history.location.state.isHomeData) {
      // DATA COME FROM HOME PAGE SEE ALL
      setSubCategories(history.location.state.subCategories);
      setIsLoading(false);
    } else {
      history.push(URL_HOME);
    }
    // eslint-disable-next-line
  }, [history, params]);

  const fetchDisesaseListByCategory = async (categoryID) => {
    let formData = new FormData();
    if (Number(params.categoryID) > 0) {
      formData.append("category_id", params.categoryID);
    } else {
      formData.append("category_id", params.categoryID);
      formData.append("source", "Website");
    }

    const list = await new Api().post("Products/getDiseaseOfcategory", {
      data: formData,
    });

    if (list.status !== CODES.SUCCESS) {
      dispatch(showToast(list.data.message));
      return;
    }

    setSubCategories(list.data.data);
    setIsLoading(false);
    ref.current.scrollTo(0, 0);
  };

  const redirectToHome = () => {
    history.push(URL_HOME);
  };

  const redirectToProductList = (item) => {
    history.push({
      pathname: `/product-list/${item.url_slung ? item.url_slung : item.id}`,
      state: {
        category: {
          name: item.category_name,
        },
      },
    });
  };

  return (
    <SubCategoriesWrapper>
      <SeoTags
        title={
          history.location.state && history.location.state.category
            ? history.location.state.category.name
            : "Shop by Product"
        }
      />
      <div className="category-container">
        <div className="breadcrumbs" ref={ref}>
          <div className="container breadcrumb">
            <div className="element pointer" onClick={redirectToHome}>
              Home
            </div>
            <div className="seperator element"> {">"} </div>
            <div className="element">
              {history.location.state && history.location.state.category
                ? history.location.state.category.name
                : "Shop by Product"}
            </div>
          </div>
        </div>
        {isLoading ? (
          <ProgressBar />
        ) : (
          <div className="container">
            <div className="category-list">
              {subCategories &&
                subCategories.map((item) => (
                  <div
                    className="media-wrapper"
                    key={item.id ? item.id : Math.random()}
                    onClick={() => redirectToProductList(item)}
                  >
                    <div className="media-container">
                      <div
                        className="image"
                        style={{ backgroundImage: `url("${item.image_url}")` }}
                      ></div>
                      <Tooltip
                        title={
                          item.category_name ? item.category_name : item.name
                        }
                        placement="bottom"
                      >
                        <div className="name elipsis">
                          {item.category_name ? item.category_name : item.name}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </SubCategoriesWrapper>
  );
}
