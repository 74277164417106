import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { URL_HOME } from "Helpers/Paths";

function ProtectedRoute(props) {
  const { isLoggedIn, children, userInfo } = props;
  return (
    <div>
      {!isLoggedIn || !userInfo ? (
        <Redirect to={URL_HOME} />
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </div>
  );
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  userInfo: state.Auth.userInfo,
});

export default connect(mapReduxStateToProps, null)(ProtectedRoute);
