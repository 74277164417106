import { css } from "styled-components";

// COLOR PALLET
export const COLORS = {
  // BLUE
  PRIMARY: "#034623",
  PRIMARY_LIGHT: "#46775e8a",
  PRIMARY_DARK: "#02120a",

  // ORANGE
  SECONDARY: "#FAFAFD",
  // SECONDARY_DARK  : "#ab003c",
  // SECONDARY_LIGHT : "#f73378",

  TITLE_TEXT_COLOR: "#1D1C1D",
  LINE_COLOR: "#e6eaf0",
  SETTINGS_TITLE_TEXT_COLOR: "#222A33",
  TERTIARY: "#3A82A7",
  TERTIARY_LIGHT: "#92abbd",

  //RED
  DANGER: "#d32f2f",
  GREY_TEXT_COLOR_DARK: "#5E6367",
  HEADER_BACKGROUND: "#242F3A",
  GREY_TEXT_COLOR: "#9494AE",
};

// BREAKPOINTS
export const BREAKPOINTS_VALUE = {
  DISPLAY: 1750,
  DESKTOP: 1450, // x_large
  LAPTOP: 1278, // large
  TABLET: 960, // medium
  PHABLET: 600, // small
  MOBILE: 450, // x_small
  S_MOBILE: 325, // XX_small
};
export const BREAKPOINTS = {
  A_DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP}px`,
  A_LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP}px`,
  A_TABLET: `${BREAKPOINTS_VALUE.TABLET}px`,
  A_PHABLET: `${BREAKPOINTS_VALUE.PHABLET}px`,
  A_MOBILE: `${BREAKPOINTS_VALUE.MOBILE}px`,

  DISPLAY: `${BREAKPOINTS_VALUE.DISPLAY - 1}px`,
  DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP - 1}px`, // x_large
  LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP - 1}px`, // large
  TABLET: `${BREAKPOINTS_VALUE.TABLET - 1}px`, // medium
  PHABLET: `${BREAKPOINTS_VALUE.PHABLET - 1}px`, // small
  MOBILE: `${BREAKPOINTS_VALUE.MOBILE - 1}px`, // x_small
  S_MOBILE: `${BREAKPOINTS_VALUE.S_MOBILE - 1}px`, // x_small
};

//Fonts
export const FONTS = {
  PRIMARY: "Poppins",
  PRIMARY_BOLD: "Poppins Bold",
  PRIMARY_SEMI_BOLD: "Poppins Semi Bold",
  PRIMARY_LIGHT: "Poppins Thin",
  PRIMARY_MEDIUM: "Poppins Medium",
};

export const responsive = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${BREAKPOINTS[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
