import React from "react";
import { NoDataFoundWrapper } from "./NoDataFound.style";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { URL_HOME } from "Helpers/Paths";

export default function NoDataFound(props) {
  const history = useHistory();
  const redirectToHome = () => {
    history.push(URL_HOME);
  };

  return (
    <NoDataFoundWrapper>
      <div className="container">
        <div className="no-data-found">
          <div className="title">
            {props.message ? props.message : "No data found."}
          </div>
          <div className="button-container">
            <Button
              variant="outlined"
              className={{ root: "root" }}
              onClick={redirectToHome}
            >
              Continue Shopping
            </Button>
          </div>
        </div>
      </div>
    </NoDataFoundWrapper>
  );
}
