import styled from "styled-components";
import { FONTS, responsive } from "Styles/Constants";

export const ProductListWrapper = styled.div`
  .product-container {
    background-color: #fff;
    margin-top: 23px;
    overflow: auto;
    //min-height: 47vh;
    ${responsive.TABLET`
      margin-top: 0px;
    `}

    ${responsive.MOBILE`
      margin-top: 60px;
    `}
    .breadcrumbs {
      background-color: #edf1f0;
      .breadcrumb {
        display: flex;
        padding: 20px 15px;
        .element {
          text-transform: uppercase;
          margin-right: 10px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 16px;
        }
        .seperator {
          font-size: 18px;
        }
      }
    }

    .product-counter {
      padding: 20px 0;
      border-bottom: 1px solid #eaeaea;
      .title {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 16px;
      }
    }

    .product-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 25px;

      ${responsive.TABLET`
          justify-content: space-between;
      `}

      .media-wrapper {
        margin: 0 40px 25px 0;
        .media-container {
          overflow-y: auto;
          overflow-x: hidden;
          width: 250px;
          min-height: 350px;
          border: 1px solid #eaeaea;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);
          cursor: pointer;
          ${responsive.PHABLET`
            width: 345px;
          `}

          .image {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 250px;
            height: 200px;
            margin: 0 auto;
            border-bottom: 1px solid #eaeaea;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);

            ${responsive.PHABLET`
              width: 345px;
            `}
          }
          .name {
            padding: 20px 10px;
            text-align: center;
            font-size: 16px;
            font-family: ${FONTS.PRIMARY_MEDIUM};
            border-bottom: 1px solid #eaeaea;
          }
          .price-wrapper {
            padding: 15px;
            .price-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .price {
                font-size: 16px;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                padding: 5px 0;
              }
            }
            .price-save {
              font-size: 16px;
              font-family: ${FONTS.PRIMARY_MEDIUM};
              color: #fe4343;
            }
          }
        }

        &.home-product {
          .media-container {
            min-height: 275px;
            .name {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
`;
