// CORE
import React from "react";
import { connect } from "react-redux";
import { logOutUser } from "Redux/Auth/Actions";

// USER DEFINED
import Routes from "Routes/Route";
import TopBar from "./Commons/Topbar/Topbar";
import EventManager from "./EventManager/EventManager";
import Footer from "Components/Commons/Footer/Footer";
import { ContentWrapper, ContainerWrapper } from "./Website.style";
import { withRouter } from "react-router-dom";

function Website(props) {
  return (
    <React.Fragment>
      <TopBar />
      <ContentWrapper>
        <ContainerWrapper>
          <Routes />
        </ContainerWrapper>
        <Footer />
      </ContentWrapper>
      <EventManager />
    </React.Fragment>
  );
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
});

export default connect(mapReduxStateToProps, { logOutUser })(
  withRouter(Website)
);
