import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { VerifyOtpWrapper } from "./Login.style";
import { Button, TextField } from "@material-ui/core";
import { showToast } from "Redux/General/Actions";
import { useDispatch } from "react-redux";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { URL_LOGIN, URL_HOME } from "Helpers/Paths";
import { loginUser } from "Redux/Auth/Actions";

//SEO
import SeoTags from "Components/SeoTags/SeoTags";

function VerifyOtp(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef1 = React.useRef();
  const inputRef2 = React.useRef();
  const inputRef3 = React.useRef();
  const inputRef4 = React.useRef();

  const { initialMinute = 0, initialSeconds = 60 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const REFS = [inputRef1, inputRef2, inputRef3, inputRef4];

  const [code, setCode] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem("loginMobile")) history.push(URL_LOGIN);
    inputRef1.current.focus();
  }, [history]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const changeFocus = (e, ref) => {
    let newCode = [...code];
    newCode[e.target.id] = e.target.value;

    setCode(newCode);

    if (ref === inputRef1) inputRef1.current.focus();
    if (ref === inputRef2) inputRef2.current.focus();
    if (ref === inputRef3) inputRef3.current.focus();
    if (ref === inputRef4) inputRef4.current.focus();
  };

  const verify = async () => {
    if (code.length !== 4) return;

    var newCode = code.reduce(function (a, b) {
      return a + b;
    });

    let formData = new FormData();
    formData.append("mobile_number", localStorage.getItem("loginMobile"));
    formData.append("otp", newCode);
    formData.append("device_id", "Web");

    let body = {
      data: formData,
    };

    const result = await new Api().post("Client/Login/OTPverification", body);

    if (result.status !== CODES.SUCCESS) {
      dispatch(showToast(result.data.message));
      return;
    }

    if (result.status === CODES.SUCCESS) {
      dispatch(showToast(result.data.message));
    }

    if (result.data.data) {
      dispatch(loginUser(result.data.data));
      localStorage.removeItem("loginMobile");
      history.push(URL_HOME);
    }
  };

  const resendOtp = async () => {
    let formData = new FormData();
    formData.append("mobile_number", localStorage.getItem("loginMobile"));

    const result = await new Api().post("Client/Login", {
      data: formData,
    });

    if (result.status !== CODES.SUCCESS) {
      dispatch(showToast(result.data.message));
      return;
    }
    dispatch(showToast("OTP has been send to your phone number."));
  };

  return (
    <VerifyOtpWrapper>
      <SeoTags />
      <div className="logo-container">
        <div className="logo"></div>
      </div>
      <div className="section">
        <div className="help-container">
          <div className="help">Need Help?</div>
        </div>
        <div className="container">
          <div className="title">Login</div>
          <div className="sub-title">
            Enter the OTP you received to +91{" "}
            {localStorage.getItem("loginMobile") &&
              localStorage.getItem("loginMobile")}
          </div>

          <div className="text-fields">
            {REFS.map((item, index) => (
              <TextField
                classes={{
                  root: "root",
                }}
                key={index}
                id={index}
                inputProps={{
                  maxLength: 1,
                  inputMode: 'numeric'
                }}
                variant="outlined"
                placeholder={0}
                inputRef={item}
                onChange={(e) => changeFocus(e, REFS[index + 1])}
              />
            ))}
          </div>

          <Button
            variant="contained"
            color="primary"
            className="verify-btn"
            onClick={verify}
          >
            Verify
          </Button>
          <div className="resend" onClick={resendOtp}>
            <div className="text">{seconds > 0 && seconds} Resend Code</div>
            <div className="icon" />
          </div>
        </div>
      </div>
    </VerifyOtpWrapper>
  );
}

export default VerifyOtp;
