import React from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import LeftArrowIcon from "Assets/Images/Slider/banner_left_arrow_unfill.png";
import RightArrowIcon from "Assets/Images/Slider/banner_right_arrow_unfill.png";
import { Button, Tooltip } from "@material-ui/core";
import { CATEGORY_TYPE } from "Helpers/Utils";
import { URL_CATEGORY } from "Helpers/Paths";
import "Styles/slick.min.css";

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);

let settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,

  nextArrow: (
    <SlickButtonFix>
      <div className="slick-next">
        <img src={RightArrowIcon} alt="Next" />
      </div>
    </SlickButtonFix>
  ),
  prevArrow: (
    <SlickButtonFix>
      <div className="slick-prev">
        <img src={LeftArrowIcon} alt="Previous" />
      </div>
    </SlickButtonFix>
  ),
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 325,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
  ],
};

export default function CategorySlider(props) {
  const { sliderData } = props;
  const history = useHistory();

  const handleRedirectSeeAll = () => {
    switch (sliderData.type) {
      case CATEGORY_TYPE.category:
        return history.push({
          pathname: URL_CATEGORY,
          state: {
            categories: sliderData.list_of_data,
            category: {
              name: sliderData.title,
            },
          },
        });
      case CATEGORY_TYPE.disease:
        return history.push({
          pathname: `/sub-categories`,
          state: {
            subCategories: sliderData.list_of_data,
            isHomeData: true,
            category: {
              name: sliderData.title,
            },
          },
        });
      case CATEGORY_TYPE.product:
        return history.push({
          pathname: `/product-list`,
          state: {
            products: sliderData.list_of_data,
            isHomeData: true,
            category: {
              name: sliderData.title,
            },
          },
        });
      default:
        return;
    }
  };

  const handleRedirect = (item) => {
    switch (sliderData.type) {
      case CATEGORY_TYPE.product:
        return history.push(
          `/product/${item.url_slung ? item.url_slung : item.id}`
        );
      case CATEGORY_TYPE.category:
        return history.push({
          pathname: `/sub-categories/${
            item.url_slung ? item.url_slung : item.id
          }`,
          state: {
            category: item,
          },
        });
      case CATEGORY_TYPE.disease:
        return history.push({
          pathname: `/product-list/${
            item.url_slung ? item.url_slung : item.id
          }`,
          state: {
            category: item,
          },
        });
      default:
        return;
    }
  };

  if (sliderData.list_of_data)
    settings.slidesToShow =
      sliderData.list_of_data.length > 4 ? 4 : sliderData.list_of_data.length;

  return (
    <div className="category-slider-container">
      <div className="container">
        <div className="title-container">
          <div className="title">{sliderData.title}</div>
          <div className="see-all">
            <Button
              color="primary"
              classes={{
                root: "root",
              }}
              onClick={handleRedirectSeeAll}
            >
              See All
            </Button>
            <div className="icon arrow" />
          </div>
        </div>

        <Slider {...settings} className="slick-slider">
          {sliderData.list_of_data &&
            sliderData.list_of_data.map((item) => (
              <div
                className="media-wrapper"
                key={item.id ? item.id : Math.random()}
                onClick={() => handleRedirect(item)}
              >
                <div className="media-container">
                  <div
                    className="image"
                    style={{ backgroundImage: `url("${item.image_url}")` }}
                  ></div>
                  <Tooltip title={item.name} placement="bottom">
                    <div className="name elipsis">{item.name}</div>
                  </Tooltip>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
}
