import axios from "axios";
import CODES from "./StatusCodes";
import { store } from "Redux/Store";
import { logOutUser } from "Redux/Auth/Actions";
// import { showToast } from "Redux/General/Actions";
import { showServerError } from "Redux/General/Actions";

import { isIEBrowser } from "Helpers/Utils";

const METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

const BASEURL = process.env.REACT_APP_API_URL;

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
  isLoggedIn = true;

  constructor() {
    this.baseURL = BASEURL;
    this.getAuthenticationInfo();
  }

  getAuthenticationInfo() {
    if (localStorage.getItem("isLoggedIn")) {
      this.isLoggedIn = true;
      this.token = localStorage.getItem("token");
    }
  }

  // URL FOR API
  // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
  get(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  api(method, url, data) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;

      axiosConfig.url = this.baseURL + url;

      axiosConfig.headers = this.setHeaders(data);
      if (data) {
        if (data.params) axiosConfig.params = data.params;

        if (data.data) axiosConfig.data = data.data;
      }

      if (isIEBrowser()) {
        if (!axiosConfig.params) axiosConfig.params = {};
        axiosConfig.params.time = new Date().getTime();
      }

      axios(axiosConfig)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === CODES.UNAUTHORIZED) {
              store.dispatch(logOutUser());
            } else if (error.response.status === CODES.INTERNAL_SERVER_ERROR) {
              if (data && !data.skipToast) store.dispatch(showServerError());
              if (data && data.skipErrorHandling) reject(error.response);
            } else {
              resolve(error.response);
            }
          } else console.log(error);
          //DEFAULT ERROR HANDLING
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    headers["Content-Type"] = "application/json";

    if (data) {
      if (data.isMultipart) {
        headers["Content-Type"] = "multipart/form-data; boundary=<calculated when request is sent>";
      }
      
      //headers["Accept"] = "*/*";
      //headers["Content-Length"] = "boundary=<calculated when request is sent>";
      //headers["Host"] = "boundary=<calculated when request is sent>";

      if (data.headers) {
        for (var key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }

    if (this.isLoggedIn !== false && !(data && data.skipAuth)) {
      headers["Authorization"] = `Bearer ${this.token}`;
    }

    return headers;
  }
}

export default Api;
