import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CategoriesWrapper } from "./Categories.style";
import { Tooltip } from "@material-ui/core";
// URL
import { URL_HOME } from "Helpers/Paths";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";

//SEO
import SeoTags from "Components/SeoTags/SeoTags";

export default function CategoryList(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (history.location.state && history.location.state.categories) {
      setCategories(history.location.state.categories);
    } else {
      fetchDisesaseListofCategories();
    }
    // eslint-disable-next-line
  }, [history]);

  const redirectToHome = () => {
    history.push(URL_HOME);
  };

  const fetchDisesaseListofCategories = async (categoryID) => {
    const list = await new Api().get("Client/Products/listOfcategories");

    if (list.status !== CODES.SUCCESS) {
      dispatch(showToast(list.data.message));
      return;
    }
    setCategories(list.data.data);
  };

  const redirectToSubCategory = (item) => {
    history.push({
      pathname: `/sub-categories/${item.url_slung ? item.url_slung : item.id}`,
      state: {
        category: item,
      },
    });
  };

  return (
    <CategoriesWrapper>
      <SeoTags
        title={
          history.location.state && history.location.state.category
            ? history.location.state.category.name
            : "Shop by Category"
        }
      />
      <div className="category-container">
        <div className="breadcrumbs">
          <div className="container breadcrumb">
            <div className="element pointer" onClick={redirectToHome}>
              Home
            </div>
            <div className="seperator element"> {">"} </div>
            <div className="element">
              {history.location.state && history.location.state.category
                ? history.location.state.category.name
                : "Shop by Category"}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="category-list">
            {categories &&
              categories.map((item) => (
                <div
                  className="media-wrapper"
                  key={item.id ? item.id : Math.random()}
                  onClick={() => redirectToSubCategory(item)}
                >
                  <div className="media-container">
                    <div
                      className="image"
                      style={{ backgroundImage: `url("${item.image_url}")` }}
                    ></div>
                    <Tooltip
                      title={item.name ? item.name : item.category_name}
                      placement="bottom"
                    >
                      <div className="name elipsis">
                        {item.name ? item.name : item.category_name}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </CategoriesWrapper>
  );
}
