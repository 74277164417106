export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isEqualsArray = (arr1, arr2) => {
  if (!(arr1 instanceof Array) || !(arr1 instanceof Array)) return false;

  if (arr1.length !== arr2.length) return false;

  if (arr1 instanceof Object && arr2 instanceof Object) {
    let isEqual = true,
      arrayLength = arr1.length;
    for (let index = 0; index < arrayLength && isEqual; index++) {
      if (JSON.stringify(arr1[index]) !== JSON.stringify(arr2[index]))
        isEqual = false;
    }

    return isEqual;
  }

  return false;
};

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function isIEBrowser() {
  // BROWSER CHECK VARIABLES
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const msie11 = ua.indexOf("Trident/");
  // const msedge = ua.indexOf('Edge/');
  return msie > 0 || msie11 > 0;
  // const isEdge = msedge > 0;
}

export function getAvatarNameIcon(userName) {
  if (!userName) return;

  let name = userName;
  let sepName = name.split(" ");
  let concatName = sepName[0].charAt(0).toUpperCase();
  if (sepName[1]) concatName += sepName[1].charAt(0).toUpperCase();
  if (!sepName[1] && sepName[2])
    concatName += sepName[2].charAt(0).toUpperCase();

  return concatName;
}

export const CATEGORY_TYPE = {
  category: "category",
  disease: "disease",
  product: "product",
  knowledgeCenter: "knowledgeCenter",
  advertiseBanner: "advertiseBanner",
};

/*export const RAZORPAY_ENV = "TEST"; // "LIVE / TEST"

export const RAZORPAY_CREDENTIALS = {
  TEST: {
    key: "rzp_test_pDezUErYydDGt9",
    key_secret: "CdFkyGzMyna1XLIlyG4d1BZj",
  },
  LIVE: {
    key: "rzp_live_r4q1hXJxACTM5M",
    key_secret: "KP89M8Lv5mQoByX33hqmTpzv",
  },
  MERCHANT_NAME: "Swasth Arogya",
};*/

export const PAYMENT_METHOD = {
  COD: "COD", // Cash On Delivery
  PPD: "Prepaid", // Prepaid
};

export const COPOUN_TYPE = {
  0: "price",
  1: "percentage",
};

export const DISCOUNT_ON_ALL_PRODUCT = (products) => {
  let discount = 0;

  products.forEach((item) => {
    item.price = Number(item.price);
    item.p_actual_price = Number(item.p_actual_price);
    if (item.p_actual_price > item.price) {
      let diff = item.p_actual_price - item.price;
      discount = diff * Number(item.quantity);
    }
  });

  return discount;
};

export const TOTAL_AMOUNT = (cartTotal, deviveryCharge, coupon) => {
  let total = 0;
  total = Number(cartTotal);
  if (cartTotal <= MIN_ORDER_AMOUNT) total = total + deviveryCharge;

  // COPOUN_TYPE
  if (coupon) {
    if (coupon.price_type === 0) {
      total = total - Number(coupon.price);
    } else if (coupon.price_type == "1") {
      let price = (total * Number(coupon.price)) / 100;
      total = total - Number(price);
    }
  }

  return total;
};

export const COUPON_DISCOUNT = (cartTotal, coupon) => {
  let discount = 0;

  // COPOUN_TYPE
  if (coupon.price_type === 0) {
    discount = Number(coupon.price);
  } else if (coupon.price_type == "1") {
    let price = (Number(cartTotal) * Number(coupon.price)) / 100;
    discount = Number(price);
  }

  return discount;
};

export const DELIVERY_CHARGE = 70;
export const MIN_ORDER_AMOUNT = 499;

export const CCAVENU_CONFIG = {
  merchant_id: "364349",
  working_key: "5FA4FE5A0093AEFD283D1448C491E60D",
  access_code: "AVNT05ID98AD12TNDA",
  redirect_url: "http://swastharogya.com/orders?type=success",
  cancel_url: "http://swastharogya.com/orders?type=failed",
};