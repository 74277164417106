import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContactUsDailogWrapper } from "./ContactUsDialog.style";
import { Button, TextField, MenuItem } from "@material-ui/core";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";

// Formik
import { Formik, Form } from "formik";

// Yup for creating validation schema
import * as Yup from "yup";

const defaultInitialValues = {
  name: "",
  comment: "",
  email: "",
  number: "",
  category: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter name."),
  comment: Yup.string().required("Please enter comment name."),
  email: Yup.string()
    .email("Please enter valid email address.")
    .required("Please enter email."),
  number: Yup.string().required("Please enter mobile no."),
  category: Yup.string().required("Please select category."),
});

export default function CustomizedDialogs(props) {
  const dispatch = useDispatch();
  let { userInfo } = useSelector((state) => state.Auth);
  let { categories } = useSelector((state) => state.General);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  useEffect(() => {
    if (userInfo && userInfo.id) {
      initialValues["name"] = userInfo.first_name;
      initialValues["comment"] = userInfo.comment;
      initialValues["email"] = userInfo.email;
      initialValues["number"] = userInfo.mobile_no;
      setInitialValues({ ...initialValues });
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const handleSubmit = async (values) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("number", values.number);
    formData.append("email", values.email);
    formData.append("comment", values.comment);
    formData.append("category", values.category);
    formData.append("source", "website");

    // ADD Address
    const updateProfile = await new Api().post("lead-form", {
      data: formData,
      isMultipart: true,
    });

    if (updateProfile.status !== CODES.SUCCESS) {
      dispatch(showToast(updateProfile.data.message));
      return;
    }
    dispatch(showToast(updateProfile.data.message));

    props.closeContactUsPopup(true);
  };

  return (
    <ContactUsDailogWrapper
      onClose={props.closeContactUsPopup}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
    >
      <div className="title-container">
        <div className="title">Contact Us</div>
        <div
          aria-label="close"
          className="close"
          onClick={props.closeContactUsPopup}
        >
          X
        </div>
      </div>

      <div className="profile-container">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleChange, values, errors, touched, setFieldValue }) => {
            return (
              <Form>
                <div className="field">
                  <React.Fragment>
                    <div className="label">
                      Name<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="name"
                        name="name"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Enter Name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      <div className="error-text">
                        {touched.name && errors.name}
                      </div>
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div className="label">
                      Email<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="email"
                        name="email"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Enter email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      <div className="error-text">
                        {touched.email && errors.email}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="label">
                      Mobile<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="number"
                        name="number"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Enter mobile"
                        onChange={handleChange}
                        value={values.number}
                      />
                      <div className="error-text">
                        {touched.number && errors.number}
                      </div>
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div className="label">
                      Category<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        select
                        value={values.category}
                        variant="outlined"
                        onChange={(e) =>
                          setFieldValue(`category`, e.target.value)
                        }
                        classes={{
                          root: "root",
                        }}
                      >
                        {categories &&
                          categories.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      <div className="error-text">
                        {touched.category && errors.category}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="label">
                      Comment<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="comment"
                        name="comment"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Comment"
                        onChange={handleChange}
                        value={values.comment}
                      />
                      <div className="error-text">
                        {touched.comment && errors.comment}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
                <div className="button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{ root: "root" }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ContactUsDailogWrapper>
  );
}
