import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

// COMPONENTS
import Login from "Components/Login/Login";
import VerifyOtp from "Components/Login/VerifyOTP";
import Dashboard from "Components/Dashboard/Dashboard";
import Categories from "Components/Categories/Categories";
import SubCategories from "Components/SubCategories/SubCategories";
import Product from "Components/Product/Product";
import ProductList from "Components/ProductList/ProductList";
import ComingSoon from "Components/ComingSoon/ComingSoon";
import KnowledgeCenterList from "Components/KnowledgeCenterList/KnowledgeCenterList";
import GhareluUpcharList from "Components/GhareluUpcharList/GhareluUpcharList";
import GhareluUpcharDetail from "Components/GhareluUpcharDetail/GhareluUpcharDetail";
import KnowledgeCenterDetail from "Components/KnowledgeCenterDetail/KnowledgeCenterDetail";
import AboutUs from "Components/AboutUs/AboutUs";
import PrivacyPolicy from "Components/PrivacyPolicy/PrivacyPolicy";

// ROUTING COMPONENTS
import ProtectedRoute from "./ProtectedRoute";
import RoutesList from "./RoutesList";
import {
  URL_LOGIN,
  URL_HOME,
  URL_CATEGORY,
  URL_SUB_CATEGORY,
  URL_VERIFY_OTP,
  URL_PRODUCT,
  URL_PRODUCT_LIST,
  URL_ABOUT_US,
  URL_PRIVACY_POLICY,
  URL_DONATE_NOW,
  URL_KNOWLEDGE_CENTER,
  URL_GHARELU_UPCHAR,
  URL_KNOWLEDGE_CENTER_DETAILS,
  URL_GHARELU_UPCHAR_DETAILS,
} from "Helpers/Paths";

function Routes() {
  return (
    <Switch>
      <Route path={URL_LOGIN} exact={true} component={() => <Login />} />
      <Route
        path={URL_VERIFY_OTP}
        exact={true}
        component={() => <VerifyOtp />}
      />
      <Route path={URL_HOME} exact={true} component={() => <Dashboard />} />
      <Route
        path={URL_CATEGORY}
        exact={true}
        component={() => <Categories />}
      />
      <Route path={URL_ABOUT_US} exact={true} component={() => <AboutUs />} />
      <Route
        path={URL_DONATE_NOW}
        exact={true}
        component={() => <ComingSoon />}
      />
      <Route
        path={URL_KNOWLEDGE_CENTER}
        exact={true}
        component={() => <KnowledgeCenterList />}
      />
      <Route
        path={URL_KNOWLEDGE_CENTER_DETAILS}
        exact={true}
        component={() => <KnowledgeCenterDetail />}
      />

      <Route
        path={URL_GHARELU_UPCHAR}
        exact={true}
        component={() => <GhareluUpcharList />}
      />

      <Route
        path={URL_GHARELU_UPCHAR_DETAILS}
        exact={true}
        component={() => <GhareluUpcharDetail />}
      />

      <Route
        path={URL_SUB_CATEGORY}
        exact={true}
        component={() => <SubCategories />}
      />
      <Route path={URL_PRODUCT} exact={true} component={() => <Product />} />

      <Route
        path={URL_PRODUCT_LIST}
        exact={true}
        component={() => <ProductList />}
      />
      <Route path={URL_PRIVACY_POLICY} exact={true} component={() => <PrivacyPolicy />} />
      <ProtectedRoute>
        {RoutesList.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </ProtectedRoute>
    </Switch>
  );
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
});

export default connect(mapReduxStateToProps, null)(Routes);
