import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GhareluUpcharWrapper } from "./GhareluUpcharDetail.style";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
// URL
import { URL_HOME, URL_GHARELU_UPCHAR } from "Helpers/Paths";
import ProgressBar from "Components/Commons/ProgressBar/ProgressBar";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";

//SEO
import SeoTags from "Components/SeoTags/SeoTags";

export default function GharelUpcharDetail() {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const redirectToHome = () => {
    history.push(URL_HOME);
  };

  const redirectToGhareluUpchar = () => {
    history.push(URL_GHARELU_UPCHAR);
  };

  useEffect(() => {
    fetchGharelUpcharDetail();
    // eslint-disable-next-line
  }, []);

  const fetchGharelUpcharDetail = async () => {
    if (params && params.id) {
      let formData = new FormData();

      if (Number(params.id) > 0) {
        formData.append("gharelu_upchar_id", params.id);
      } else {
        formData.append("gharelu_upchar_id", params.id);
        formData.append("source", "Website");
      }

      const data = await new Api().post(
        "Client/Dashboard/details_of_gharelu_upchar",
        {
          data: formData,
        }
      );

      if (data.status !== CODES.SUCCESS) {
        dispatch(showToast(data.data.message));
        return;
      }
      if (data.data.data) {
        setData(data.data.data);
      } else {
        setData(null);
      }
      setIsLoading(false);
    }
  };

  return (
    <GhareluUpcharWrapper>
      <SeoTags title={data && data.title} />
      {isLoading ? (
        <ProgressBar />
      ) : (
        <React.Fragment>
          {data && (
            <React.Fragment>
              <div id="breadcrumbs" className="breadcrumbs">
                <div className="container breadcrumb">
                  <div className="element pointer" onClick={redirectToHome}>
                    Home
                  </div>
                  <div className="seperator element"> {">"} </div>
                  <div
                    className="element pointer"
                    onClick={redirectToGhareluUpchar}
                  >
                    Gharelu Upchar
                  </div>
                  <div className="seperator element"> {">"} </div>
                  <div className="element">{data && data.title}</div>
                </div>
              </div>
              <div className="container">
                <div className="gharelu-upchar">
                  {data.image_video && data.type === "video" ? (
                    <div className="video-container">
                      <div className="media-container" key={Math.random()}>
                        <ReactPlayer
                          url={data.image_video}
                          className="react-player"
                          controls={true}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="image-container">
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url("${data.image_video}")`,
                        }}
                      ></div>
                    </div>
                  )}
                  <div className="title-container">
                    <div className="title">{data && data.title}</div>
                  </div>
                  {data.description && (
                    <div className="description-container">
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: data.description,
                        }}
                      ></div>
                    </div>
                  )}

                  {data.short_description && (
                    <div className="description-container">
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: data.short_description,
                        }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </GhareluUpcharWrapper>
  );
}
