import { ACTION_TYPES } from "./Actions";

const initState = {
  isLoggedIn: localStorage.getItem("isLoggedIn")
    ? localStorage.getItem("isLoggedIn") === "true"
    : false,
  userInfo:
    localStorage.getItem("userInfo") &&
    localStorage.getItem("userInfo") !== "undefined"
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  token: null,
  defaultAddress: null,
  cartData: null,
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_USER:
      return {
        ...state,
        userInfo: { ...action.userInfo },
        token: action.token,
        isLoggedIn: true,
      };

      case ACTION_TYPES.LOGOUT_USER:
      console.log("🚀 ~ file: Reducer.js ~ line 56 ~ Reducer ~ ACTION_TYPES.LOGOUT_USER", state)
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
        token: null,
        defaultAddress: null,
        cartData: null,
      };

    case ACTION_TYPES.UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: { ...action.values },
      };

    case ACTION_TYPES.DEFAULT_ADDRESS:
      return {
        ...state,
        defaultAddress: { ...action.values },
      };
    case ACTION_TYPES.FETCH_CART:
      return {
        ...state,
        cartData: { ...action.values },
      };
    default:
      return state;
  }
};

export default Reducer;
