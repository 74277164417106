import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FooterContainer } from "./Footer.style";
import { Button } from "@material-ui/core";
import CallNowPopup from "./CallNowPopup";
import ContactUsDialog from "Components/ContactUs/ContactUsDialog";
import TelephoneIcon from "Assets/Images/Footer/telephone.png";
import {
  URL_ABOUT_US,
  URL_PRIVACY_POLICY,
  //URL_DONATE_NOW,
  URL_GHARELU_UPCHAR,
  URL_KNOWLEDGE_CENTER,
} from "Helpers/Paths";

export default function Footer() {
  let { categories } = useSelector((state) => state.General);
  console.log(
    "🚀 ~ file: Footer.jsx ~ line 19 ~ Footer ~ categories",
    categories
  );
  const [openCallNow, setOpenCallNow] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const redirectToAppDownloadPage = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.swastharogya",
      "_blank"
    );
  };

  const openCallNowPopup = () => {
    setOpenCallNow(true);
  };

  const closeCallNowPopup = () => {
    setOpenCallNow(false);
  };

  const closeContactUsPopup = () => {
    setOpenContactUs(false);
  };

  const redirectTo = (url) => {
    window.open(url, "_blank");
  };

  const openContactUsPopup = () => {
    setOpenContactUs(true);
  };

  return (
    <FooterContainer>
      <div className="container section-container">
        <div className="section-information">
          <div className="title-conainer">
            <div className="title">Information</div>
          </div>
          <div className="link-container">
            <div className="link">
              <Link to="/">Home</Link>
            </div>
            <div className="link">
              <Link to="/sub-categories/1">Shop by Product</Link>
            </div>
            <div className="link">
              <Link to={URL_KNOWLEDGE_CENTER}>Knowledge Center</Link>
            </div>
            <div className="link">
              <Link to={URL_GHARELU_UPCHAR}>Gharelu Upchar</Link>
            </div>
            {/*<div className="link">
              <Link to={URL_DONATE_NOW}>Donate Now</Link>
            </div>*/}
            <div className="link">
              <Link to={URL_ABOUT_US}>About Us</Link>
            </div>
            <div className="link">
              <Link to={URL_PRIVACY_POLICY}>Privacy Policy</Link>
            </div>
            <div className="link">
              <Link to={"#"} onClick={openContactUsPopup}>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className="section-categories">
          <div className="title-conainer">
            <div className="title">Categories</div>
          </div>
          <div className="link-container">
            {categories &&
              categories.map((item) => (
                <div className="link" key={item.id}>
                  <Link
                    to={`/sub-categories/${
                      item.url_slung ? item.url_slung : item.id
                    }`}
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
          </div>
        </div>
        <div className="section-address">
          <div className="title-conainer">
            <div className="title">Contacts</div>
          </div>
          <div className="address">
            <div className="field">
              <span className="white">Address: &nbsp;</span>
              <span>
                332-335, Trade Square, Khokhra Cir, Maninagar, Ahmedabad,
                Gujarat 380008
              </span>
            </div>
            <div className="field">
              <span className="white">Email: &nbsp;</span>
              <span>info@swastharogya.com</span>
            </div>
            <div className="field">
              <span className="white">Phone: &nbsp;</span>
              <span>072289 56789</span>
            </div>
          </div>
          <div className="button-container">
            <Button
              variant="outlined"
              classes={{
                root: "root",
              }}
              startIcon={<img src={TelephoneIcon} alt="phone" />}
              //onClick={"document.location.href='tel:07228956789'"}
              onClick={openCallNowPopup}
            >
              Call Now
            </Button>
          </div>
        </div>
      </div>
      <div className="section-coptright">
        <div className="container">
          <div className="coptright-container">
            <div className="playstore-container">
              <div
                className="icon play-store pointer"
                onClick={redirectToAppDownloadPage}
              />
            </div>
            <div className="copyright">
              <div className="text">Copyright@ SHEETAL NIRAJ MEHTA.</div>
              <div className="text">All Rights Reserved</div>
            </div>
            {/*<div className="payment-providers">
              <div className="icon payu" />
              <div className="icon gpay" />
              <div className="icon paytm" />
              <div className="icon phonepe" />
              <div className="icon mobikwik" />
            </div>*/}
            <div className="social-media">
              <div
                className="icon fb"
                onClick={() =>
                  redirectTo("https://www.facebook.com/swastharogya")
                }
              />
              <div
                className="icon insta"
                onClick={() =>
                  redirectTo("https://www.instagram.com/swastharogya")
                }
              />
              <div
                className="icon google-plus"
                onClick={() => redirectTo("https://www.g.page/swastharogya")}
              />
              <div
                className="icon youtube"
                onClick={() =>
                  redirectTo("https://www.youtube.com/swastharogya")
                }
              />
              <div
                className="icon linkedin"
                onClick={() =>
                  redirectTo("https://www.linkedin.com/company/swastharogya")
                }
              />
              <div
                className="icon whatsapp"
                onClick={() => redirectTo("https://wa.me/917228956789")}
              />
              <div
                className="icon twitter"
                onClick={() =>
                  redirectTo("https://www.twitter.com/swastharogya")
                }
              />
            </div>
          </div>
        </div>
      </div>
      {openCallNow && (
        <CallNowPopup
          open={openCallNow}
          closeCallNowPopup={closeCallNowPopup}
        />
      )}
      {openContactUs && (
        <ContactUsDialog
          open={openContactUs}
          closeContactUsPopup={closeContactUsPopup}
        />
      )}
    </FooterContainer>
  );
}
