// CORE
import React, { Component } from "react";
import { connect } from "react-redux";

import Toast from "Components/Commons/Toast/Toast";

import { hideToast } from "Redux/General/Actions";
import { updateUserInfo, logOutUser } from "Redux/Auth/Actions";
import { URL_LOGIN } from "Helpers/Paths";
import { withRouter } from "react-router-dom";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";

class EventManager extends Component {
  render() {
    return (
      <React.Fragment>
        <Toast
          open={this.props.toast.enable}
          message={this.props.toast.message}
          handleClose={this.props.hideToast}
        />

        {this.props.isLoggedIn &&
          this.props.location.pathname !== URL_LOGIN &&
          this.props.location.pathname !== "/" && (
            <AfterLoginEvents userInfo={this.props.userInfo} />
          )}
      </React.Fragment>
    );
  }
}

class AfterLoginEvents extends React.Component {
  async componentDidMount() {
    if (this.props && this.props.userInfo && this.props.userInfo.id) {
      let formData = new FormData();
      formData.append("client_id", this.props.userInfo.id);
      const user = await new Api().post("Client/Profile", {
        data: formData,
      });

      if (user.status !== CODES.SUCCESS) {
        logOutUser();
        return;
      }
      updateUserInfo(user.data.data);
    }
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

const mapReduxStateToProps = (state) => {
  return {
    toast: state.General.toast,
    isLoggedIn: state.Auth.isLoggedIn,
    userInfo: state.Auth.userInfo,
  };
};

export default connect(mapReduxStateToProps, {
  hideToast,
  updateUserInfo,
  logOutUser,
})(withRouter(EventManager));
