import React from "react";
import Slider from "react-slick";
import LeftArrowIcon from "Assets/Images/Slider/banner_left_arrow_unfill.png";
import RightArrowIcon from "Assets/Images/Slider/banner_right_arrow_unfill.png";

import "Styles/slick.min.css";

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,
  nextArrow: (
    <SlickButtonFix>
      <div className="slick-next">
        <img src={RightArrowIcon} alt="Next" />
      </div>
    </SlickButtonFix>
  ),
  prevArrow: (
    <SlickButtonFix>
      <div className="slick-prev">
        <img src={LeftArrowIcon} alt="Previous" />
      </div>
    </SlickButtonFix>
  ),
};

export default function BannerSlider(props) {
  const { sliderValue } = props;

  return (
    <Slider {...settings} className="slick-slider">
      {sliderValue.map((item) => (
        <div className="media-container" key={item.product_id}>
          {item.type === "image" ? (
            <div
              className="image"
              style={{ backgroundImage: `url("${item.url}")` }}
            ></div>
          ) : (
            <div className="video"></div>
          )}
        </div>
      ))}
    </Slider>
  );
}
