import React from "react";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { CATEGORY_TYPE } from "Helpers/Utils";
import { URL_CATEGORY, URL_KNOWLEDGE_CENTER } from "Helpers/Paths";
import "Styles/slick.min.css";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,
};

export default function KnowledgeCenter(props) {
  const { sliderData } = props;
  const history = useHistory();

  const handleRedirect = () => {
    switch (sliderData.type) {
      case CATEGORY_TYPE.category:
        return history.push(URL_CATEGORY);
      case CATEGORY_TYPE.knowledgeCenter:
        return history.push(URL_KNOWLEDGE_CENTER);
      default:
        return;
    }
  };

  return (
    <div className="container">
      <div className="knowledge-center-container">
        <div className="knowledge-title">
          <div className="title">
            <div className="text">Knowledge</div>
            <div className="text">Center</div>
          </div>
          <div className="see-all">
            <Button
              color="primary"
              classes={{
                root: "root",
              }}
              onClick={handleRedirect}
            >
              See All
            </Button>
            <div className="icon arrow" />
          </div>
        </div>
        <Slider {...settings} className="slick-slider">
          {sliderData.list_of_data &&
            sliderData.list_of_data.map((item) => (
              <div
                className="media-container"
                key={item.id ? item.id : Math.random()}
              >
                <ReactPlayer
                  url={item.url}
                  className="react-player"
                  controls={true}
                />
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
}
