import React from "react";
import { ComingSoonWrapper } from "./CoomingSoon.style";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { URL_HOME } from "Helpers/Paths";

//SEO
import SeoTags from "Components/SeoTags/SeoTags";

export default function ComingSoon(props) {
  const history = useHistory();
  const redirectToHome = () => {
    history.push(URL_HOME);
  };

  return (
    <ComingSoonWrapper>
      <SeoTags />
      <div className="container">
        <div className="no-data-found">
          <div className="title">
            {props.message ? props.message : "Coming Soon !!"}
          </div>
          <div className="button-container">
            <Button
              variant="outlined"
              className={{ root: "root" }}
              onClick={redirectToHome}
            >
              Continue Shopping
            </Button>
          </div>
        </div>
      </div>
    </ComingSoonWrapper>
  );
}
