import React from "react";
import { DownlaodAppWrapper } from "./DownlaodApp.style";
import { Button } from "@material-ui/core";

export default function CustomizedDialogs(props) {
  const redirectToAppDownloadPage = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.swastharogya",
      "_blank"
    );
  };

  return (
    <DownlaodAppWrapper
      onClose={props.closeDownloadApp}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
    >
      <div className="title-container">
        <div className="title">Please download mobile application.</div>
        <div
          aria-label="close"
          className="close"
          onClick={props.closeDownloadApp}
        >
          X
        </div>
      </div>
      <div className="button-container">
        <Button
          color="primary"
          classes={{ root: "root" }}
          variant="outlined"
          onClick={redirectToAppDownloadPage}
        >
          Downlaod App
        </Button>
      </div>
    </DownlaodAppWrapper>
  );
}
