import styled from "styled-components";
import { FONTS, responsive } from "Styles/Constants";

export const KnowledgeCenterListWrapper = styled.div`
  .knowledge-center-container {
    background-color: #fff;
    margin-top: 23px;
    overflow: auto;
    ${responsive.TABLET`
      margin-top: 0px;
    `}

    ${responsive.MOBILE`
      margin-top: 60px;
    `}
    
    .breadcrumbs {
      background-color: #edf1f0;
      .breadcrumb {
        display: flex;
        padding: 20px 15px;
        .element {
          text-transform: uppercase;
          margin-right: 10px;
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 16px;
        }
        .seperator {
          font-size: 18px;
        }
      }
    }

    .knowledge-center-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 25px;

      ${responsive.TABLET`
          justify-content: space-between;
      `}

      .media-wrapper {
        margin: 0 25px 25px 0;
        cursor: pointer;
        ${responsive.PHABLET`
          margin: 0 auto 25px;
        `}
  
        .media-container {
          overflow-y: auto;
          overflow-x: hidden;
          width: 280px;
          min-height: 275px;
          border: 1px solid #eaeaea;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);
          ${responsive.PHABLET`
            width: 160px;
            min-height: 160px;
          `}
          .image {
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            height: 200px;
            width: 280px;
            margin: 0 auto;
            border-bottom: 1px solid #eaeaea;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);
            background-color: #000;

            ${responsive.PHABLET`
              width: 160px;
              height: 160px;
            `}
          }
          .name {
            margin: 25px 15px;
            text-align: center;
            font-size: 16px;
            font-family: ${FONTS.PRIMARY_MEDIUM};
            padding: 0 10px;
            ${responsive.PHABLET`
              font-size: 14px;
              margin: 15px 10px;
            `}
          }
        }
      }
    }
  }
`;
