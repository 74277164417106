import styled from "styled-components";

import { Drawer } from "@material-ui/core";

import { COLORS, FONTS, responsive } from "Styles/Constants";
import SideBarLinkIcon from "Assets/Images/Sidebar/Sidebar.svg";

export const SidebarWrapper = styled(Drawer)`
  .drawer-paper {
    background-color: #fafafd;
    width: 220px;
    margin: 70px 0 0;
    border-right: 1px solid ${COLORS.LINE_COLOR};
    ${responsive.MOBILE`
      margin: 50px 0 0;
    `}
  }

  .logout-text {
    color: #000;
    text-decoration: none;
    padding: 15px 0;
    text-align: left;
    margin-left: 20px;
    text-align: left;
    font-size: 14px;
    font-family: ${FONTS.PRIMARY};
    cursor: pointer;

    &:hover {
      color: ${COLORS.PRIMARY};
    }
  }
`;

export const UrlWrapper = styled.div`
  display: flex;
  padding: 6% 5%;
  align-items: center;

  &.link {
    cursor: pointer;
    &.active {
      background-color: #fff1dd;
    }

    .icon {
      /* background-image     : url(${SideBarLinkIcon}); */
      height: 25px;
      width: 25px;
      background-position: ${(props) => props.position};
      background-size: ${(props) => (props.size ? props.size : "280px")};
      background-repeat: no-repeat;
    }
    .text {
      font-size: 14px;
      margin: 0 0 0 10px;
      font-family: ${FONTS.PRIMARY};

      &.active {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        color: ${COLORS.PRIMARY};
      }
    }
  }
`;
