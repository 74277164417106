import styled from "styled-components";
import { FONTS, responsive } from "Styles/Constants";
import ArrowIcon from "Assets/Images/Commons/arrow.png";

export const DashboardWrapper = styled.div`
  margin-top: 25px;
  min-height: 48vh;
  ${responsive.TABLET`
    margin-top: 0px;
  `}

  ${responsive.MOBILE`
    margin-top: 60px;
  `}
  
  .banner-container {
    .slick-slider {
      display: flex;
      .media-container {
        .image {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 350px;
          width: 1920px;

          ${responsive.DISPLAY`
            background-size: contain;
            height: 292px;
            width: 1600px;
          `}

          ${responsive.DESKTOP`
            background-size: contain;
            height: 250px;
            width: 1366px;
          `}

          ${responsive.LAPTOP`
            background-size: contain;
            height: 190px;
            width: 1024px;
          `}

          ${responsive.TABLET`
            background-size: contain;
            height: 142px;
            width: 768px;
          `}

          ${responsive.PHABLET`
            background-size: contain;
            height: 10vh;
            width: 100vw;
          `}
        }
      }

      .slick-next {
        position: absolute;
        z-index: 1000;
        top: 40%;
        right: 5%;
        cursor: pointer;

        ${responsive.LAPTOP`
            img {
              height:20px;
              width:20px;
            }
        `}

        ${responsive.PHABLET`
            img {
              height:18px;
              width:18px;
            }
        `}
      }

      .slick-prev {
        position: absolute;
        z-index: 1000;
        top: 40%;
        left: 5%;
        cursor: pointer;

        ${responsive.LAPTOP`
            img {
              height:20px;
              width:20px;
            }
        `}

        ${responsive.PHABLET`
            img {
              height:18px;
              width:18px;
            }
        `}
      }
    }
  }

  .slider-type-wrapper {
    margin: 0 0 0 0;
    overflow: auto;
    &.white-bg {
      background-color: #fff;
    }

    &.category {
      background-color: #fff;
    }

    .category-slider-container {
      padding: 25px 0 30px 0;
      ${responsive.TABLET`
        padding: 15px 0 15px 0;
      `}

      ${responsive.MOBILE`
        padding: 0 0 15px 0;
      `}

      .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          font-size: 26px;
          font-family: ${FONTS.PRIMARY_BOLD};
          ${responsive.TABLET`
            font-size: 20px;
          `}

          ${responsive.MOBILE`
            font-size: 18px;
          `}
        }

        .see-all {
          display: flex;
          align-items: center;
          cursor: pointer;

          .root {
            font-size: 18px;
            font-family: ${FONTS.PRIMARY_BOLD};
            margin: 20px 0;
            text-transform: uppercase;
            border-radius: 4px;
            color: #000;

            ${responsive.TABLET`
              font-size: 16px;
              margin: 15px 0;
            `}

            ${responsive.MOBILE`
              margin: 10px 0;
            `}
          }
          .icon {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            &.arrow {
              background-image: url(${ArrowIcon});
            }

            ${responsive.TABLET`
              height: 15px;
              width: 15px;
            `}
          }
        }
      }

      .slick-slider {
        .media-wrapper {
          .media-container {
            overflow-y: auto;
            overflow-x: hidden;
            width: 250px;
            min-height: 275px;
            border: 1px solid #eaeaea;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);
            cursor: pointer;

            ${responsive.LAPTOP`
              width: 250px;
              min-height: 277px;
            `}

            ${responsive.TABLET`
              width: 220px;
              min-height: 250px;
            `}

            ${responsive.PHABLET`
              width: 160px;
              min-height: 120px;
            `}

            ${responsive.S_MOBILE`
              margin: 0 auto;
            `}

            .image {
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              width: 250px;
              height: 200px;
              margin: 0 auto;
              border-bottom: 1px solid #eaeaea;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);
              background-color: #fff;

              ${responsive.PHABLET`
                width: 160px;
                height: 160px;
              `}
            }
            .name {
              margin: 25px 0;
              text-align: center;
              font-size: 16px;
              font-family: ${FONTS.PRIMARY_MEDIUM};
              cursor: pointer;
              padding: 0 10px;

              ${responsive.LAPTOP`
                font-size: 14px;
              `}

              ${responsive.PHABLET`
                 margin: 15px 10px;
              `}
            }
          }
        }

        .slick-next {
          position: absolute;
          z-index: 1000;
          top: 40%;
          right: -12%;
          cursor: pointer;

          ${responsive.DESKTOP`
            right: -8%;
          `}
        }

        .slick-prev {
          position: absolute;
          z-index: 1000;
          top: 40%;
          left: -12%;
          cursor: pointer;

          ${responsive.DESKTOP`
            left: -8%;
          `}
        }
      }
    }

    &.category {
      .media-container {
        min-height: 270px !important;
        ${responsive.PHABLET`
          min-height: 120px !important;
        `}

        .image {
          background-size: cover !important;
        }
      }
    }

    &.disease {
      .media-container {
        .image {
          background-size: cover !important;
        }
      }
    }

    .knowledge-center-container {
      display: flex;
      align-items: center;
      background-color: #fff;
      margin: 25px 0 0 0;
      ${responsive.TABLET`
        flex-wrap: wrap;
      `}

      .knowledge-title {
        width: 30%;
        text-align: right;
        margin: 0;

        ${responsive.TABLET`
          display: flex;
          align-item:center;
          justify-content: space-between;
          width: 100%;
        `}

        .title {
          text-align: left;
          margin: 50px 0;

          ${responsive.TABLET`
            margin: 30px 0;
          `}

          ${responsive.MOBILE`
            margin: 10px 0;
          `}

          .text {
            font-size: 52px;
            font-family: ${FONTS.PRIMARY_BOLD};

            ${responsive.LAPTOP`
              font-size: 28px;
            `}

            ${responsive.TABLET`
              margin-right:10px;
              font-size: 20px;
            `}
          }

          ${responsive.TABLET`
            display: flex;
            align-item:center;
          `}
        }

        .see-all {
          display: flex;
          align-items: center;
          cursor: pointer;

          ${responsive.MOBILE`
            width: 100%;
            justify-content: flex-end;
          `}

          ${responsive.MOBILE`
            margin: 0 0 10px 0;
          `}

          .root {
            font-size: 18px;
            font-family: ${FONTS.PRIMARY_BOLD};
            margin: 20px 0;
            text-transform: uppercase;
            border-radius: 4px;
            color: #000;

            ${responsive.TABLET`
              font-size: 18px;
            `}

            ${responsive.MOBILE`
              margin: 0;
            `}
          }
          .icon {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            cursor: pointer;
            ${responsive.TABLET`
              height: 15px;
              width: 15px;
            `}

            &.arrow {
              background-image: url(${ArrowIcon});
            }
          }
        }
      }

      .slick-slider {
        margin: 0 0 50px 0;
        width: 70%;

        ${responsive.TABLET`
          width: 100%;
        `}

        ${responsive.PHABLET`
          width: 100%;
          order:1;
          margin: 0 0 20px 0;
        `}

        .media-container {
          .react-player {
            margin: 0 auto;
            ${responsive.PHABLET`
              width: 330px !important;
              height: 185px !important;
              overflow-x: hidden;
            `}
          }
        }
      }
    }

    .advertisment-container {
      margin: 25px 0;
      .media-container {
        .image {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 250px;
          width: 400px;
        }
      }
    }
  }
`;
