import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProductWrapper } from "./Product.style";
import { useParams } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ProductGallery from "./Include/ProductGallery";
import ReactPlayer from "react-player";
import ExpandMoreIcon from "Assets/Images/Product/arrow-down.png";
import ConfromDialog from "./Include/ConfirmPopup";
// URL
import { URL_CART, URL_HOME, URL_LOGIN } from "Helpers/Paths";
import ProgressBar from "Components/Commons/ProgressBar/ProgressBar";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { setUserCartData } from "Redux/Auth/Actions";
import { showToast } from "Redux/General/Actions";

//SEO
import SeoTags from "Components/SeoTags/SeoTags";

export default function Product() {
  let { userInfo, isLoggedIn, cartData } = useSelector((state) => state.Auth);
  const myRef = useRef(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const executeScroll = (ref) => {
    window.scrollTo(0, ref.current.offsetTop);
  };

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [productData, setProductData] = useState(null);
  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: false,
    panel3: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    let panels = expanded;
    panels[panel] = !panels[panel];
    setExpanded({ ...panels });
  };

  const redirectToHome = () => {
    history.push(URL_HOME);
  };

  useEffect(() => {
    fetchProductDetail();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    
    if(productData && localStorage.getItem('referralUrl')) {
      localStorage.removeItem('referralUrl')
      AddtoCart();
    }
  }, [productData]);

  const fetchProductDetail = async () => {
    if (params && params.productID) {
      let formData = new FormData();
      if (Number(params.productID) > 0) {
        formData.append("product_id", params.productID);
      } else {
        formData.append("product_id", params.productID);
        formData.append("source", "Website");
      }

      const productData = await new Api().post(
        "Client/Products/ProductDetails",
        {
          data: formData,
        }
      );

      if (productData.status !== CODES.SUCCESS) {
        dispatch(showToast(productData.data.message));
        return;
      }
      if (productData.data.data) {
        setProductData(productData.data.data[0]);
      } else {
        setProductData(null);
      }
      setIsLoading(false);
      // executeScroll(myRef);
    }
  };

  const AddtoCart = async (redirectToCart = false) => {
    if (isLoggedIn && productData.id && userInfo.id) {
      let formData = new FormData();
      formData.append("client_id", userInfo.id);
      formData.append("product_id", productData.id);
      formData.append("quantity", 1);

      const cart = await new Api().post("Client/Cart/add", {
        data: formData,
      });
      if (cart.status !== CODES.SUCCESS) {
        dispatch(showToast(cart.data.message));
        return;
      }
      //dispatch(showToast(cart.data.message));

      if (cartData) {
        let updateCart = { ...cartData };
        if (updateCart.total_quantity)
          updateCart.total_quantity = Number(updateCart.total_quantity) + 1;
        else updateCart.total_quantity = 1;

        dispatch(setUserCartData(updateCart));
        setOpenConfirmDialog(true);
      }

      if (redirectToCart) history.push(URL_CART);
    } else {
      localStorage.setItem("referralUrl", `/product/${params.productID}`);
      history.push(URL_LOGIN);
    }
  };

  const closeConfirmPopup = (orderDetails) => {
    setOpenConfirmDialog(false);
  };

  return (
    <ProductWrapper ref={myRef}>
      <SeoTags
        title={productData && productData.name}
        metaDescription={productData && productData.meta_description}
      />
      {isLoading ? (
        <ProgressBar />
      ) : (
        <React.Fragment>
          {productData && (
            <React.Fragment>
              <div id="breadcrumbs" className="breadcrumbs">
                <div className="container breadcrumb">
                  <div className="element pointer" onClick={redirectToHome}>
                    Home
                  </div>
                  <div className="seperator element"> {">"} </div>
                  <div className="element">
                    {productData && productData.name}
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="product">
                  <div className="product-slider">
                    <ProductGallery
                      images={productData.image_url}
                      productData={productData}
                    />
                  </div>
                  <div className="product-details">
                    <div className="name">{productData.name}</div>
                    <div className="tags-container">
                      <div className="tags">{productData.p_secondary_name}</div>
                    </div>
                    <div className="mobile-product-gallery">
                      <ProductGallery
                        images={productData.image_url}
                        productData={productData}
                      />
                    </div>
                    <div className="actual-price">
                      <del>
                        <spna className="title">M.R.P : ₹ </spna>
                        {productData.p_actual_price}
                      </del>
                    </div>
                    <div className="price">
                      <spna className="title">Price : </spna>
                      <spna className="price-color">
                        ₹ {productData.price}{" "}
                      </spna>
                      <span className="tax">incl. all tax</span>
                    </div>
                    {Number(productData.p_actual_price) -
                      Number(productData.price) >
                      0 && (
                      <div className="save">
                        <spna className="title">You save: </spna>
                        <spna className="price-color">
                          ₹ {productData.p_actual_price - productData.price}
                        </spna>
                      </div>
                    )}
                    <div className="button-container">
                      <div className="add-to-cart">
                        <Button
                          variant="contained"
                          color="primary"
                          classes={{ root: "root" }}
                          onClick={() => AddtoCart()}
                        >
                          Add to Cart
                        </Button>
                      </div>
                      <div className="buy-now">
                        <Button
                          variant="contained"
                          color="primary"
                          classes={{ root: "root" }}
                          onClick={() => AddtoCart(true)}
                        >
                          Buy Now
                        </Button>
                      </div>
                    </div>
                  </div>
                  {productData.video_url && (
                    <div className="video-container">
                      <div className="media-container" key={Math.random()}>
                        <ReactPlayer
                          url={productData.video_url}
                          className="react-player"
                          controls={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="description-container">
                    <Accordion
                      className="accordian"
                      expanded={expanded.panel1}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={<img src={ExpandMoreIcon} alt="expand" />}
                      >
                        <div className="name">Feature & Details</div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: productData.features_and_details,
                          }}
                        ></div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="description-container">
                    <Accordion
                      className="accordian"
                      expanded={expanded.panel2}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<img src={ExpandMoreIcon} alt="expand" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="name">About Product</div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: productData.p_description,
                          }}
                        ></div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="description-container">
                    <Accordion
                      className="accordian"
                      expanded={expanded.panel3}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<img src={ExpandMoreIcon} alt="expand" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="name">Important Information</div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: productData.important_information,
                          }}
                        ></div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {openConfirmDialog && (
        <ConfromDialog
          open={openConfirmDialog}
          closeConfirmPopup={closeConfirmPopup}
          productData={productData}
        />
      )}
    </ProductWrapper>
  );
}
