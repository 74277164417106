import React from "react";
import { ConfirmPopup } from "../Product.style";
import { Button } from "@material-ui/core";

export default function ConfromDialog(props) {
  return (
    <ConfirmPopup
      onClose={props.closeConfirmPopup}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
    >
      <div className="text">Product Successfully added into your cart.</div>
      <div className="button-container">
        <Button
          variant="outlined"
          color="primary"
          classes={{ root: "root" }}
          onClick={props.closeConfirmPopup}
        >
          Okay
        </Button>
      </div>
    </ConfirmPopup>
  );
}
