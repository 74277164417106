import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";
import { Dialog } from "@material-ui/core";

export const ProductWrapper = styled.div`
  overflow: auto;
  margin-top: 23px;
  background-color: #fff;
  //min-height: 48vh;
  overflow: auto;
  ${responsive.TABLET`
      margin-top: 0px;
  `}

  ${responsive.MOBILE`
      margin-top: 60px;
  `}
  
  .breadcrumbs {
    background-color: #edf1f0;
    .breadcrumb {
      display: flex;
      padding: 20px 15px;
      .element {
        text-transform: uppercase;
        margin-right: 10px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 16px;
      }
      .seperator {
        font-size: 18px;
      }
    }
  }
  .tags-container {
    margin: 10px 0;
    .tags {
      color: #aaaaaa;
    }
  }
  .product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .product-slider {
      width: 40%;

      ${responsive.PHABLET`
        width: 100%;
      `}
      ${responsive.MOBILE`
        order: 2;
        display:none; // Mobile view there another div
      `}
    }
    .product-details {
      width: 60%;
      padding: 15px 25px;
      box-sizing: border-box;
      ${responsive.PHABLET`
        width: 100%;
        padding: 10px;
        `}

      ${responsive.MOBILE`
        order: 1;
      `}
      
      .name {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 22px;
        margin-bottom: 15px;
      }
      .mobile-product-gallery {
        display: none;
        ${responsive.MOBILE`
          display: block;
        `}
      }

      .actual-price {
        color: #aaaaaa;
        font-size: 18px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        ${responsive.TABLET`
          font-size: 16px;
        `}
      }
      .price {
        color: #aaaaaa;
        font-size: 18px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        margin: 10px 0;
        ${responsive.TABLET`
          font-size: 16px;
        `}
        .tax {
          font-size: 14px;
          ${responsive.TABLET`
            font-size: 12px;
          `}
        }
        .price-color {
          color: #ff3c3c;
        }
      }
      .save {
        font-size: 18px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        color: #aaaaaa;
        .title {
          min-width: 150px;
        }
        ${responsive.TABLET`
          font-size: 16px;
        `}
        .price-color {
          color: #ff3c3c;
        }
      }
      .button-container {
        margin-bottom: 10px;
        .root {
          text-transform: none;
          min-width: 230px;
          margin: 15px 0 0;
          padding: 15px 0;
          background-color: #009036;
          ${responsive.MOBILE`
            margin: 10px 0 0;
            min-width: 200px;
            padding: 10px 0;
          `}
        }
      }
    }
    .video-container {
      margin: 0 auto 25px;
      ${responsive.MOBILE`
        order: 3;
      `}
      .react-player {
        //width: 900px !important;
        ${responsive.PHABLET`
          width: 330px !important;
          height: 185px !important;
          overflow: hidden;
        `}
      }
    }
    .description-container {
      border-top: 2px solid #c1c1c1;
      padding: 15px 0;
      width: 100%;
      .accordian {
        box-shadow: none;
      }
      .name {
        color: ${COLORS.PRIMARY};
        font-size: 20px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
      }
      .description {
        font-family: ${FONTS.PRIMARY_MEDIUM};
      }

      ${responsive.MOBILE`
        order: 3;
      `}
    }
  }
`;

export const ProductGalleryWrapper = styled.div`
  .ps-product__variants {
    margin: 15px 0;

    .image-gallery {
      .image-gallery-left-nav {
        display: none;
      }

      .image-gallery-right-nav {
        display: none;
      }

      .image-gallery-thumbnail.active {
        border: 2px solid ${COLORS.PRIMARY};
      }

      .image-gallery-thumbnail:hover {
        border: 2px solid ${COLORS.PRIMARY};
      }

      .image-gallery-thumbnail {
        border: 2px solid transparent;
        cursor: pointer;
      }

      .image-gallery-image {
        cursor: pointer;
      }
    }
  }
`;

export const ConfirmPopup = styled(Dialog)`
  .text {
    font-size: 16px;
    padding: 15px;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    margin: 8px 0;
    text-align: center;
  }

  .button-container {
    padding: 0 15px 15px;
    text-align: center;
    .root {
      background-color: #009036;
      font-family: ${FONTS.PRIMARY_MEDIUM};
      font-size: 14px;
      padding: 10px;
      min-width: 150px;
      text-transform: none;
      margin-right: 10px;
      border: none;
      color: #fff;
    }
  }
`;

export const ProductImageWrapper = styled(Dialog)`
  .media-popup {
    display: flex;
    ${responsive.TABLET`
      display: block;
    `}
    .main-media-wrapper {
      min-height: 600px;
      ${responsive.MOBILE`
        min-height: 250px;
      `}

      .image {
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 600px;
        width: 600px;
        ${responsive.TABLET`
          margin : 0 auto;
        `}

        ${responsive.MOBILE`
          height: 250px;
          width: 250px;
        `}
      }
    }

    .product-gallery {
      position: relative;
      width: 90%;
      box-sizing: border-box;
      overflow-x: hidden;

      .product-name {
        h1 {
          font-family: ${FONTS.PRIMARY_MEDIUM};
          font-size: 22px;
          margin: 15px;
        }
        ${responsive.TABLET`
          display: none;
        `}
      }
      .tags-container {
        margin: 15px;
        .tags {
          color: #aaaaaa;
        }
        ${responsive.TABLET`
          display: none;
        `}
      }

      .thumb-wrapper {
        width: 250px;
        margin: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        ${responsive.TABLET`
          width: 100%;
        `}

        .media-container {
          min-height: 80px;
          cursor: pointer;

          .image {
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            height: 100px;
            width: 100px;
            border: 2px solid transparent;

            &.active {
              border: 2px solid ${COLORS.PRIMARY};
            }
          }
        }
      }
    }
    .close {
      font-family: ${FONTS.PRIMARY_MEDIUM};
      font-size: 22px;
      margin: 15px;
      cursor: pointer;
      ${responsive.MOBILE`
          display:none;
      `}
    }
  }
`;
