import styled from "styled-components";
import { responsive, COLORS } from "Styles/Constants";
import { CircularProgress, Avatar } from "@material-ui/core";

export const ContentWrapper = styled.div`
  padding: 90px 0 0;
  height: calc(100% - 90px);
  overflow-x: hidden;
  overflow-y: scroll;
  ${responsive.TABLET`
    padding: 80px 0 0;
  `}
`;

export const ContainerWrapper = styled.div`
  //width: ${(props) => `calc(100% - ${props.width})`};
  margin: 0 0 0 auto;
  height: auto;

  ${responsive.TABLET`
    width  : 100%;
  `}

  .pointer {
    cursor: pointer;
  }

  .disabled-item {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const CircularProgressBar = styled(CircularProgress)`
  &.root {
    display: block;
    margin: 0 auto;
    display: block;
  }
`;

export const AvatarWrapper = styled(Avatar)`
  &.root {
    width: 40px;
    height: 40px;
    font-size: 18px;
    background-color: ${COLORS.PRIMARY};

    &:hover {
      cursor: pointer;
    }
  }
`;
