import styled from "styled-components";
import { FONTS, responsive } from "Styles/Constants";

export const AboutWrapper = styled.div`
  margin: 50px 25px;
  font-family: ${FONTS.PRIMARY_LIGHT};
  min-height: 38.5vh;
  text-align: justify;
  font-size: 1.9em;
  font-weight: bold;
  word-spacing: 0.1em;

  ${responsive.TABLET`
      margin-top: 0px;
  `}

  ${responsive.MOBILE`
      margin-top: 80px;
  `}
`;
